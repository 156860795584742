import React, { useEffect, useState } from 'react';
// Base Web-Frameworks
import {
  Card,
  StyledBody,
  StyledAction,
} from "baseui/card";
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Spinner } from "baseui/spinner";
import { MdDelete } from 'react-icons/md';
import { API, graphqlOperation } from 'aws-amplify';
import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";
import { InputValidation, setValue, SubmitValidation } from '../../../utils/Form';
import FileUploader from '../../../components/FileUploader.js';
import { CreateTerminal } from '../../../utils/Create';
import '../../../styles/index.css';
import { getFastCustomer, getFastMember, getFastTerminal } from '../../../graphql/queries';
import { UpdateTerminal } from '../../../utils/Updates';
import { terminalDelete } from '../utils/handlers';
import Modals from '../../../components/Modals';
import { UIButtonForm } from '../../../components/UIComponents';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import Geocode from 'react-geocode'

Geocode.setApiKey("AIzaSyCjDtbSM_fk8MM9pHkEXZqMf4ijQhRWJoM");
Geocode.enableDebug();

const mapStyles = {
  width: '100%',
  height: '100%',
};

const AddTerminalForm = ({ ...props }) => {
  let { params } = props.props.match
  let { history } = props.props
  const [terminalName, setTerminalName] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [address, setAddress] = useState('');
  const [disable, setDisable] = useState(false);
  const [photo, setPhoto] = useState({});
  const [focus, setFocus] = useState(false);
  const [errors, setErrors] = useState({
    terminalName: '',
    address: ''
  });
  const [modal, setModal] = React.useState(false);
  const [modalProps, setModalProps] = React.useState({
    cancel: { text: '', func: () => { } },
    confirm: { text: '', func: () => { } },
    text: '',
    title: '',
  });
  const [tid, setTid] = useState('');
  const [spin, setSpinner] = useState(false);
  const [edit, setEdit] = useState(false);
  const [position, setPosition] = useState(null)

  const getTerminalInfo = React.useCallback(async (tid) => {
    setSpinner(true);
    let res = await API.graphql(graphqlOperation(getFastTerminal, { id: tid }));
    res = res.data.getFastTerminal;
    if (res) {
      setEdit(true);
      setTerminalName(res.terminalName ? res.terminalName : '')
      setAddress(res.terminalAddress ? res.terminalAddress : '')
      setPhoto(res.terminalPhoto ? res.terminalPhoto : '');
      let lat = parseFloat(res.terminalLat)
      let lng = parseFloat(res.terminalLong)
      setPosition({
        lat,
        lng
      })
      const user = JSON.parse(localStorage.getItem('@loggedUser'));
      await API.graphql(graphqlOperation(getFastCustomer, { id: user })).then(result => {
        if (result.data.getFastCustomer && result.data.getFastCustomer.defaultTerminalId === res.id) {
          setIsDefault(true);
          setDisable(true);
        }
      })
    } else {
      history.push('/terminals');
    }
    setSpinner(false);
  }, [history])

  useEffect(() => {
    let tid = params && params.tid ? params.tid : null;
    setTid(tid);
    if (tid !== null) {
      getTerminalInfo(tid);
    }
  }, [params, getTerminalInfo])

  useEffect(() => {
    let tid = params && params.tid ? params.tid : null;
    setTid(tid);
    if (tid !== null) {
      getTerminalInfo(tid);
    }
  }, [])

  const handleFocus = flag => {
    setFocus(flag);
    setErrors({
      ...errors,
      form: ''
    })
  }

  const handleDeleteClick = (tid) => {
    terminalDelete(tid, { setModalProps, setSpinner, setModal }, props.props)
  }


  const submitHandler = async () => {
    const formValid =
      SubmitValidation(
        [
          { id: 'address', value: address },
          { id: 'terminalName', value: terminalName },
        ],
        errors,
        focus,
        setErrors
      );
    if (!formValid) {
      document.getElementById('form-error').scrollIntoView();
      return;
    } else {
      setSpinner(true);
      const customerId = JSON.parse(localStorage.getItem('@loggedUser'));
      const userGrp = JSON.parse(localStorage.getItem('@loggedUserGroup'));
      let temp = customerId;
      let notOwner = ["FASTLevel2", "FASTLevel3", "FASTLevel4"].includes(userGrp);
      if (notOwner) {
        await API.graphql(
          graphqlOperation(
            getFastMember, { id: temp }
          )
        ).then(res => {
          temp = res.data.getFastMember.customerId;
        });
      }
      let input = {
        customerId: temp,
        terminalName,
        terminalAddress: address,
        isDefault,
        isDeactivated: false,
        terminalPhoto: photo,
        terminalLat: position.lat,
        terminalLong: position.lng
      }
      if (!edit)
        await CreateTerminal(props.props, input, [setPhoto, setTerminalName, setAddress])
      else if (edit) {
        input.id = tid;
        await UpdateTerminal(props.props, input, [setPhoto, setTerminalName, setAddress])
      }
    }
    setSpinner(false);
  }

  return spin ? <Spinner size={92} /> : (
    <>
      <Modals
        type={modalProps.type}
        status={modal}
        toggler={st => setModal(st)}
        cancel={modalProps.cancel}
        confirm={modalProps.confirm}
        text={modalProps.text}
        title={modalProps.title}
      />
      <Card
        overrides=
        {{
          Title: { style: { display: "flex", justifyContent: "center", paddingBottom: "5px" } }
        }}
        title={edit ? "Update Terminal" : "Add Terminal"}
        id="form-error"
        className="form-card"
      >
        {edit && !isDefault && <div style={{
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer'
        }}>
          <MdDelete
            size="25px"
            title="Remove Terminal"
            onClick={() => handleDeleteClick(tid)} />
        </div>}
        <StyledBody>
          <div className="form-error-container">
            {errors.form && (
              <text
                className="form-error"
              >
                {" "}
                {errors.form}
              </text>
            )}
          </div>
          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <FormControl label="Terminal Name" error={errors.terminalName}>
                <Input
                  overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                  value={terminalName}
                  autoFocus={edit}
                  onFocus={() => handleFocus(true)}
                  onChange={e => setValue('terminalName', e.target.value, setTerminalName, setErrors, errors)}
                  onBlur={() => InputValidation('terminalName', [terminalName], errors, setErrors)}
                  error={errors.terminalName}
                  placeholder="Enter Terminal Name"
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>

          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem overrides=
              {{
                Block: {
                  style:
                  {
                    margin: "8px"
                  }
                }
              }}>
            </FlexGridItem>
          </FlexGrid>
          <FormControl label="Address" error={errors.terminalAddress} >
            <Input
              overrides={{ Input: { style: { backgroundColor: 'white' } } }}
              value={address}
              required
              placeholder="Click Map to enter address"
              error={errors.address}
              disabled
            />
          </FormControl>

          <div className="map_canvas" style={{ marginTop: 0 }}>
            <div style={{ fontWeight: 500, fontSize: 14, color: '#000', marginBottom: 8 }}>Address:</div>
            <div>
              <Map
                google={props.google}
                zoom={14}
                style={mapStyles}
                fullscreenControl={false}
                initialCenter={position ? position : { lat: 33.8476798, lng: -117.9813616 }}
                onClick={async (a, m, e) => {
                  setPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() })
                  let res = await Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng())
                  if (res.results && res.results.length > 0) {
                    setAddress(res.results[0].formatted_address)
                  }
                }}
              >
                {
                  <Marker
                    position={position}
                  />
                }

              </Map>
            </div>
          </div>





          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <FormControl >
                <Checkbox
                  disabled={disable}
                  checked={isDefault}
                  onChange={e => setIsDefault(e.target.checked)}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  Set As Default
                </Checkbox>
              </FormControl>
            </FlexGridItem>
          </FlexGrid>

          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem overrides={{ Block: { style: { border: errors.address && "2px solid red", margin: "8px" } } }}>
              <FormControl label="Terminal Photo">
                <FileUploader
                  pic={photo}
                  picFunc={setPhoto}
                  multiple={false}
                />
              </FormControl>
            </FlexGridItem>

          </FlexGrid>

        </StyledBody>
        <StyledAction>
          <FlexGrid
            flexGridColumnCount={[1, 1, 2]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <UIButtonForm onClick={() => { history.push('/terminals') }} color="black" bgcolor="white">
                Cancel
              </UIButtonForm>
            </FlexGridItem>

            <FlexGridItem>
              <UIButtonForm onClick={submitHandler} >
                {edit ? "Update Terminal" : "Add Terminal"}
              </UIButtonForm>
            </FlexGridItem>
          </FlexGrid>
        </StyledAction>
      </Card>
    </>
  )
};


export default GoogleApiWrapper({
  apiKey: 'AIzaSyAeeZxOp86E53PKfERC6j1UpNxZuURBY7I'
})(AddTerminalForm);