import React, { useEffect, useState } from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import tractor from "../tractor-128.png";
import van from "../van.png";
import bus from "../bus.png";
import truck from "../truck.png";
import trailer from "../trailer.png";
import pickup from "../pickup.png";
import map_plus from '../map_plus.png'
const mapStyles = {
    width: '100%',
    height: '95%',
};

const VehicleMap = ({ data, initialCenter, ...props }) => {
    const [terminalsIdList, setTerminalsIdList] = useState({})
    const [showData, setShowData] = useState([])
    useEffect(() => {
        let copyTerminalIdList = {}
        let copyIsOpenList = {}
        let copyShowData = []
        data.forEach(item => {

            if (!copyTerminalIdList[item.terminalId]) {
                copyTerminalIdList[item.terminalId] = 1
                copyShowData.push(item)
                copyIsOpenList[item.terminalId] = false
            } else {
                copyTerminalIdList[item.terminalId]++
            }
        })
        setTerminalsIdList(copyTerminalIdList)
        setShowData(copyShowData)
    }, [data])


    return (
        <Map
            google={props.google}
            zoom={10}
            style={mapStyles}
            initialCenter={initialCenter}
            fullscreenControl={false}
        >
            {
                showData.map((item, index) => {
                    let src = ''
                    switch (item.type.toUpperCase()) {
                        case "TRACTOR":
                            src = tractor;
                            break;
                        case "TRUCK":
                            src = truck;
                            break;
                        case "TRAILER":
                            src = trailer;
                            break;
                        case "VAN":
                            src = van;
                            break;
                        case "BUS":
                            src = bus;
                            break;
                        case "PICKUPTRUCK":
                            src = pickup;
                            break;
                        default:
                            break
                    }
                    return terminalsIdList[item.terminalId] === 1 ? <Marker
                        key={index}
                        icon={{
                            url: src,
                            scaledSize: new props.google.maps.Size(30, 30)
                        }}
                        position={{
                            lat: item.lat,
                            lng: item.lng
                        }}
                        onClick={() => props.MapClickHandler(item)}
                    /> : <Marker
                        key={index}
                        icon={{
                            url: map_plus,
                            scaledSize: new props.google.maps.Size(30, 30)
                        }}
                        position={{
                            lat: item.lat,
                            lng: item.lng
                        }}
                        onClick={() => props.clickTerminalIcon(item.terminalId)}
                    >

                    </Marker>

                })
            }

        </Map>
    )
}


export default GoogleApiWrapper({
    apiKey: 'AIzaSyAeeZxOp86E53PKfERC6j1UpNxZuURBY7I',
})(VehicleMap);