import React from 'react';
import { FileUploader } from 'baseui/file-uploader';
import { Tag, SIZE, KIND, VARIANT } from 'baseui/tag';

export default (props) => {
  return (
    <>
      {(!props.pic.name && !props.pic.key) && (
        <FileUploader
          onDrop={(acc, rej) => {
            props.picFunc(acc[0]);
          }}
          multiple={props.multiple}
          accept="image/*,.pdf"
        />
      )}
      {(props.pic.name || props.pic.key) && (
        <span>
          <Tag
            size={SIZE.large}
            kind={KIND.black}
            variant={VARIANT.solid}
            onActionClick={() => {
              props.picFunc({})
            }}

          >
            {props.pic.name && props.pic.name}
            {props.pic.key && props.pic.key}
          </Tag>
        </span>
      )}
    </>
  );
};