import React from 'react'

import CustomHeader from '../../components/Header'
// import SigninForm from './components/SigninForm';
import '../../styles/index.css';
import { CalbitLogo } from '../../assests/SVGs';
import { CopyRightFooter } from '../../components/UIComponents';
import '../SignIn/styles/signin.css';
import Form from './components/Form';
const ForgotPassword = (props) => {

    return (
        <>
            <CustomHeader props={props} />
            <div className="flex-container">
                <div className="flex-left-container">
                    <div className="flex-left-item">
                        <div className="flex-left-item-logo">
                            <CalbitLogo />
                        </div>
                    </div>
                </div>
                <div className="flex-right-container">
                    <Form history={props.history} />
                </div>
            </div>
            <CopyRightFooter />
        </>
    )
}

export default ForgotPassword
