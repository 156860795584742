import React from 'react'

import CustomHeader from '../../components/Header'
import AddVehicleForm from './components/AddVehicleForm'
import '../../styles/index.css'

const AddVehicle = (props) => {

    return (
        <>
            <CustomHeader props={props} />
            <div className="container" >
                <AddVehicleForm
                    props={props}
                />
            </div>
        </>
    )
}

export default AddVehicle
