import React, { useEffect } from 'react'
import { Spinner } from 'baseui/spinner';
import { CalbitLogo } from '../../assests/SVGs';
import CustomHeader from '../../components/Header'
import SideButtons from './components/SideButtons'
import { CopyRightFooter } from '../../components/UIComponents'
import StickerDisplay3 from './components/Opt_StickerUpdated';
import { getUserCognito } from '../../utils/Auth'
import '../../styles/index.css';

const StartView = (props) => {
  const [spinner, setSpinner] = React.useState(true);

  useEffect(() => {
    getUserCognito(null, setSpinner, props);
  }, [props])
  return (
    <>
      {spinner ?
        <div className="container">
          <Spinner size={92} />
        </div>
        :
        <>
          <CustomHeader props={props} />
          <div className="main-container">
            <div className='card-header'>
              <CalbitLogo />
            </div>

              <div className="main-content">
                <div className="zoom-view">
                  <StickerDisplay3
                    props={props}
                    setSpinner={setSpinner}
                    spinner={spinner}
                  />
                </div>
                <div className="btn-view">
                  <SideButtons />
                </div>
              </div>
          </div>
          <CopyRightFooter />
        </>
      }
    </>
  )
}



export default StartView;