import React, { useState } from 'react'
import {
  Card,
  StyledBody,
  StyledAction,
} from 'baseui/card';
import { FormControl } from 'baseui/form-control';

import { InputValidation, setValue, SubmitValidation } from '../../../utils/Form';
import { setSignIn } from '../../../utils/Auth';
import '../../../styles/index.css';
import '../styles/signinform.css'
import { BiShow, BiHide } from 'react-icons/bi'
import { UIButtonForm } from '../../../components/UIComponents';
import { Spinner } from 'baseui/spinner';

export default ({ props, spin, spinner }) => {
  const [username, setUsername] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [focus, setFocus] = useState(false);
  const [errors, setErrors] = useState({
    username: '',
    userPassword: '',
    form: ''
  });
  const [show, setShow] = useState(false);

  const handleFocus = flag => {
    setFocus(flag);
    setErrors({
      ...errors,
      form: ''
    })
  }

  const submitHandler = () => {
    const formValid = SubmitValidation(
      [
        { id: 'userPassword', value: userPassword },
        { id: 'username', value: username },
      ],
      errors,
      focus,
      setErrors
    );
    if (!formValid) {
      document.getElementById('form-error').scrollIntoView();
      return;
    } else {
      setSignIn(spin, props, username, userPassword);
    }
  }

  return (
    <Card
      overrides={{ Root: { style: { width: '100%', backgroundColor: '#F6F6F6', padding: '7% 5%' } } }}
      // title="Login Form"
      id="form-error"
    >
      <StyledBody>
        <div className="form-error-container">
          {errors.form && (
            <text
              className="form-error"
            >
              {" "}
              {errors.form}
            </text>
          )}
        </div>
        <FormControl error={errors.username} >
          <div className="input-group">
            <input
              disabled={spinner}
              className="input-txt"
              placeholder="Username"
              onKeyDown={e => {
                if (e.charCode === 13 || e.keyCode === 13) {
                  submitHandler();
                }
              }}
              value={username}
              onFocus={() => handleFocus(true)}
              onChange={e => {
                setValue('username', e.target.value, setUsername, setErrors, errors)
                InputValidation('username', [e.target.value], errors, setErrors)
              }}
              onBlur={() => InputValidation('username', [username], errors, setErrors)}
              error={errors.username}
            />
          </div>
        </FormControl>
        <FormControl error={errors.userPassword}>
          <div className="input-group">
            <input
              className="input-pw"
              id="in-pw"
              disabled={spinner}
              onKeyDown={e => {
                if (e.charCode === 13 || e.keyCode === 13) {
                  submitHandler();
                }
              }}
              placeholder="Password"
              type="password"
              value={userPassword}
              onFocus={() => handleFocus(true)}
              onChange={e => setValue('userPassword', e.target.value, setUserPassword, setErrors, errors)}
              onBlur={() => InputValidation('userPassword', [userPassword], errors, setErrors)}
              error={errors.userPassword}
            />
            {!show && <BiShow size={20} onClick={() => {
              var x = document.getElementById("in-pw");
              if (x.type === "password") {
                x.type = "text";
                setShow(true)
              } else {
                x.type = "password";
                setShow(false)
              }
            }} />}
            {show && <BiHide size={20} onClick={() => {
              var x = document.getElementById("in-pw");
              if (x.type === "password") {
                x.type = "text";
                setShow(true)
              } else {
                x.type = "password";
                setShow(false)
              }
            }} />}
          </div>
        </FormControl>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
          {/* <div>
            <Checkbox
              overrides={{
                Root: {
                  style: () => ({ opacity: 0.5, })
                }
              }}
            >
              Remember Me
            </Checkbox>
          </div> */}
          <div
            style={{ opacity: 0.5, cursor: 'pointer' }}
            onClick={() => props.history.push('/forgot-password')}
          >
            Forgot Password
          </div>
        </div>
        <UIButtonForm
          onClick={submitHandler}
        >
          {spinner ? <Spinner color="white" size={12} /> : "Login"}
        </UIButtonForm>
      </StyledBody>
      <StyledAction>

      </StyledAction>
    </Card>
  )
};