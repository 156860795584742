import { DeleteTerminal } from "../../../utils/Deletes";

export const terminalDelete = (tid , funcs, props) => {
    funcs.setSpinner(true);
    funcs.setModalProps({
      cancel: {text: 'No', func: () => funcs.setModal(false)},
      confirm: {text: 'Yes', func: () => {
        funcs.setSpinner(true);
        DeleteTerminal(props, {
          id: tid,
        })
        funcs.setModal(false);
        funcs.setSpinner(false);
      }}, 
      text: 'Are You Sure ?',
      type: 'Delete',
      title: 'Delete Terminal',
    })
    funcs.setModal(true);
    funcs.setSpinner(false);
}
