import React from 'react';
import '../styles/index.css'
export const UIButtonNav = ({ children, ...props }) =>
    <button
        style={{
            backgroundColor: '#293377',
            borderRadius: '5px',
            color: 'white',
            width: '100%',
            padding: '8px 20px',
            textAlign: 'center',
            display: 'inline-block',
            fontSize: '16px',
            // margin: '0px 8px ',
            cursor: 'pointer'
        }}
        {...props}
    >
        {children}
    </button>

export const UIButtonForm = ({ children, ...props }) =>
    <button
        style={{
            backgroundColor: props.disabled ? '#F6F6F6' : props.bgcolor ? props.bgcolor : '#293377',
            borderRadius: '5px',
            color: props.disabled ? 'black' : props.color ? props.color : 'white',
            width: props.mini ? '25%' : '100%',
            fontWeight: props.disabled && 'lighter',
            textAlign: 'center',
            display: 'inline-block',
            fontSize: '16px',
            margin: '0px 8px ',
            height: 40,
            cursor: props.disabled ? 'not-allowed' : 'pointer'
        }}
        {...props}
    >
        {children}
    </button>

export const CopyRightFooter = () => <div className="footer">
    &#169; {(new Date().getFullYear())} CalBit FAST
</div>