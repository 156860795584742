import {API, graphqlOperation} from 'aws-amplify';
import { getFastCustomer, getFastMember, listFastMembers, listFastTerminals, listFastVehicles } from '../graphql/queries';

const user = JSON.parse(localStorage.getItem('@loggedUser'));
const userGrp = JSON.parse(localStorage.getItem('@loggedUserGroup'));

export const fetchCustomer = async () => {
  if(["FASTLevel2", "FASTLevel3", "FASTLevel4"].includes(userGrp)) {
    let data = null;
    await API.graphql(
      graphqlOperation(
          getFastMember, {id: user}
        )
      ).then(async res => {
        await API.graphql(
          graphqlOperation(
              getFastCustomer, 
              {id: res.data.getFastMember.customerId}
            )
          ).then(res => {
            data = res.data.getFastCustomer;
          });
      });
      return {
          companyName: data.carrierName, 
          companyAddress: data.carrierAddress, 
          terminals: data.terminals.items,
          vehicles: data.vehicles.items,
          defaultTerminal: data.defaultTerminalId
      };
  } else if(['FASTCustomer'].includes(userGrp)) {
    let data = null;
    await API.graphql(
          graphqlOperation(
              getFastCustomer, {id: user}
            )
          ).then(res => {
            data = res.data.getFastCustomer;
          });
        return {
            companyName: data.carrierName, 
            companyAddress: data.carrierAddress, 
            terminals: data.terminals.items,
            vehicles: data.vehicles.items,
            defaultTerminal: data.defaultTerminalId
        };
    }
  }

export const fetchMembers = async (setSpinner, setRows) => {
    setSpinner(true);
    let data = null;
    let temp = user;
    let notOwner = ["FASTLevel2", "FASTLevel3", "FASTLevel4"].includes(userGrp);
    // let notOwner = [ "FASTLevel3", "FASTLevel4"].includes(userGrp);

    try {
      if(notOwner) {
      await API.graphql(
        graphqlOperation(
            getFastMember, {id: user}
          )
        ).then(res => {
          temp = res.data.getFastMember.customerId;
        });
      }
      await API.graphql(
        graphqlOperation(
            listFastMembers, 
            {
              limit : 100, 
              filter: {
              customerId: {
                eq: temp
                }
              }
            }
          )
        ).then(res => {
          data = res.data.listFastMembers.items;
          const temp = [];
          if(data.length > 0) {
            data.map(dt => {
              temp.push({
                id: dt.id,
                memberName: dt.memberName,
                phone: dt.phone,
                email: dt.email,
                actions: "",
                level: dt.groupsAssigned[0] === "FastCustomer" ? "Owner Level 1" : 
                        dt.groupsAssigned[0] === "FastAdminLevel2" ? "Admin Level 2" :
                        dt.groupsAssigned[0] === "FastServiceLevel3" ? "Service Level 3" :
                        dt.groupsAssigned[0] === "FastServiceLevel4" ? "Service Level 4" : "No Level Assigned",
                levelLabel: dt.groupsAssigned[0] === "FastCustomer" ? "" : 
                dt.groupsAssigned[0] === "FastAdminLevel2" ? "Add Remove Team Members, Full Fleet Administration" :
                dt.groupsAssigned[0] === "FastServiceLevel3" ? "Full Fleet Administrsation" :
                dt.groupsAssigned[0] === "FastServiceLevel4" ? "Partial Fleet Administration" : "",
              })
            return null;
            });
            let newRows = temp.map(r => ({id: r.id, data: r}));
            setRows(newRows);
            setSpinner(false);
        } else {
          setSpinner(false);
        }
        });
    } catch (error) {
      setSpinner(false);
    }
}

export const fetchTerminals = async (setSpinner, setRows) => {
    setSpinner(true);
    let data = null;
    let temp = user;
    let notOwner = ["FASTLevel2", "FASTLevel3", "FASTLevel4"].includes(userGrp);
    try {
      if(notOwner) {
      await API.graphql(
        graphqlOperation(
            getFastMember, {id: user}
          )
        ).then(res => {
          temp = res.data.getFastMember.customerId;
        });
      }
      await API.graphql(
        graphqlOperation(
            listFastTerminals, 
            {
              limit : 100, 
              filter: {
              customerId: {
                eq: temp
                }
              }
            }
          )
        ).then(res => {
          data = res.data.listFastTerminals.items;
          const temp = [];
          if(data.length > 0) {
            data.map(dt => {
              temp.push({
                id: dt.id,
                terminalName: dt.terminalName,
                terminalAddress: dt.terminalAddress,
                actions: ""
              })
            return null;
            });
            let newRows = temp.map(r => ({id: r.id, data: r}));
            setRows(newRows);
            setSpinner(false);
          } else {
            setSpinner(false);
          }
        });
    } catch (error) {
      setSpinner(false);
    }
}

export const fetchVehicles = async (setSpinner, setRows, setOptions) => {
  if(setSpinner) setSpinner(true);
    let data = null;
    let tempUsr = user;
    let notOwner = ["FASTLevel2", "FASTLevel3", "FASTLevel4"].includes(userGrp);
    try {
      if(notOwner) {
      await API.graphql(
        graphqlOperation(
            getFastMember, {id: user}
          )
        ).then(res => {
          tempUsr = res.data.getFastMember.customerId;
        });
      }
      await API.graphql(
        graphqlOperation(
            listFastVehicles, 
            {
              limit : 100, 
              filter: {
              customerId: {
                eq: tempUsr
                },
                isDeactivated: {
                  eq: false
                } 
              }
            }
          )
        ).then(res => {
          data = res.data.listFastVehicles.items;
          const tempArr = [];
          if(data.length > 0) {
            data.map(dt => {
              tempArr.push({
                vehicleId: dt.vehicleId,
                VIN: dt.VIN ? dt.VIN : dt.id,
                type: dt.type,
                isOutOfService: dt.isOutOfService,
                actions: ""
              })
            return null;
          });
            if(setRows === null || setSpinner === null){
              if(tempArr && tempArr.length > 0) {
                let temp = [];
                tempArr.map(r => {
                    temp.push({id: r.VIN, label: r.vehicleId});
                    return null;
                })
                setOptions(temp);
              }
            }
            let newRows = tempArr.map(r => ({id: r.vehicleId, data: r}));
            if(setRows) setRows(newRows);
            if(setSpinner) setSpinner(false);
          } else {
            if(setSpinner) setSpinner(false);
          }
        });
    } catch (error) {
        if(setSpinner) setSpinner(false);
    }
}