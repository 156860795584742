import * as React from "react";
import { Select, SIZE, TYPE } from "baseui/select";

export default ({
    terminalsList, 
    selectedTerminal, 
    setSelectedTerminal, 
    defaultTerminal,
    setSelected,
    setVehicleInfo,
    isLoading,
    moveable
  }) => {

  const [options, setOptions] = React.useState([]);
  React.useEffect(() => {
    let temp = [{id: "ALL", label: "All Terminals"}];
    terminalsList.map(tl => {
      if(defaultTerminal !== null && tl.id === defaultTerminal) {
        setSelectedTerminal([{
          id: tl.id,
          label: tl.terminalName
        }])
      }
        temp.push({id: tl.id, label: tl.terminalName});
        return null;
    });
    setOptions(temp);
    }, [terminalsList, defaultTerminal, setSelectedTerminal]);

    const ChangeHandler = params => {
        setSelected([]);
        setVehicleInfo(null);
        if(params.type === "select"){
            const {id} = params.value[0];
            if(id !== null){
                setSelectedTerminal(params.value);
            }
        } else {
            setSelectedTerminal([]);
            setSelected([]);
            setVehicleInfo(null);
        }
    }
    
  return (
    <Select
      options={options}
      value={selectedTerminal}
      type={TYPE.select}
      size={SIZE.mini}
      disabled={isLoading || moveable}
      placeholder="Select Terminal"
      onChange={params => ChangeHandler(params)}
      overrides={{
        Root: {
          style: {backgroundColor: "transparent", width:'100%'}}
        }}
    />
  );
}