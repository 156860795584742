import React from 'react';
import { FileUploader } from 'baseui/file-uploader';
import { Tag, SIZE, VARIANT } from 'baseui/tag';

export default (props) => {
  return (
    <>
      <FileUploader
        onDrop={(acc, rej) => {
          let temp1 = props.pic;
          let temp2 = acc;
          temp2.map(t2 => {
            temp1.map(t1 => {
              if (t2.name === t1.name && t2.type === t1.type) {
                temp2 = temp2.filter(ft => (ft.name !== t2.name && ft.type !== t2.type))
              }
              return null;
            })
            return null;
          })
          let temp3;
          if (props.pic.length > 0) {
            temp3 = temp1.concat(temp2);
            props.picFunc(temp3);
          } else props.picFunc(acc);
        }}
        multiple={true}
        accept="image/*,.pdf"

      />
      {(props.pic.length > 0) && props.pic.map(fi => {
        return (
          <>
            {(fi.key || fi.name) && <span>
              <Tag
                size={SIZE.large}
                variant={VARIANT.solid}
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#293377"
                    })
                  },
                  Text: {
                    style: ({ $theme }) => ({ color: "white" })
                  },
                  Action: {
                    style: ({ $theme }) => ({ color: "white" })
                  }
                }}
                onActionClick={() => {
                  let temp = props.pic;
                  temp = temp.filter(t => t.name !== fi.name || t.key !== fi.key);
                  props.picFunc(temp)
                }}
              >
                {fi.name && fi.name}
                {fi.key && fi.key}
              </Tag>
            </span>}
          </>
        )
      })}
    </>
  );
};