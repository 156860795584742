import React from 'react';
import {
  StatefulDataTable,
  CategoricalColumn,
  StringColumn,
  CustomColumn
} from 'baseui/data-table';
import { Spinner } from 'baseui/spinner';
import { Link } from 'react-router-dom';
import { FiEdit2 } from 'react-icons/fi';
import { CgArrowsExchangeV } from 'react-icons/cg';
import { MdDelete } from 'react-icons/md';

import '../../../styles/index.css';
import { fetchVehicles } from '../../../utils/Fetch';
// import {Tag, KIND, VARIANT} from 'baseui/tag';
import Modals from '../../../components/Modals';
import { vehicleDelete, vehicleChangeStatus } from '../utils/handlers';
import { UIButtonNav } from '../../../components/UIComponents';

const userGrp = JSON.parse(localStorage.getItem('@loggedUserGroup'));

export default ({ props }) => {
  const [rows, setRows] = React.useState([]);
  const [spinner, setSpinner] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [modalProps, setModalProps] = React.useState({
    cancel: { text: '', func: () => { } },
    confirm: { text: '', func: () => { } },
    text: '',
    title: '',
  });

  React.useEffect(() => {
    fetchVehicles(setSpinner, setRows, null);
  }, [])

  const handleChangeStatusClick = vin => {
    vehicleChangeStatus(
      vin,
      rows,
      {
        setModal,
        setSpinner,
        setRows,
        setModalProps
      },
      props
    );
  }

  const handleDeleteClick = vin => {
    vehicleDelete(
      vin,
      rows,
      {
        setModal,
        setSpinner,
        setRows,
        setModalProps
      },
      props
    );
  };

  const columns = [
    StringColumn({
      title: 'Vehicle ID',
      mapDataToValue: (data) => data.vehicleId,
    }),
    CategoricalColumn({
      title: 'Vehicle Type',
      mapDataToValue: (data) => data.type,
    }),
    StringColumn({
      title: ' Status ',
      mapDataToValue: (data) =>
        data.isOutOfService ? "Out Of Service" : "In Service"
      // data.isOutOfService ? 
      //     <>
      //       <Tag
      //       kind={KIND.negative}
      //       closeable={false}
      //       disabled={userGrp === "FASTLevel4"}
      //       onClick={() => {userGrp !== "FASTLevel4" && handleChangeStatusClick(data.VIN)}}
      //       variant={VARIANT.solid}
      //       >
      //         Out Of Service
      //       </Tag>
      //     </>
      // :
      // !data.isOutOfService ? 
      //   <>
      //   <Tag
      //       kind={KIND.positive}
      //       closeable={false}
      //       disabled={userGrp === "FASTLevel4"}
      //       onClick={() => {userGrp !== "FASTLevel4" && handleChangeStatusClick(data.VIN)}}
      //       variant={VARIANT.solid}
      //     >
      //       In Service
      //     </Tag>
      //   </>
      //   :
      // "",
    }),
    CustomColumn({
      title: 'Actions ',
      mapDataToValue: (data) => "",
      renderCell: () => {
        return null;
      }
    }),
  ];

  const rowActions = [
    {
      label: 'Edit',
      onClick: ({ row }) => { props.history.push(`/edit-vehicle/${row.data.VIN}`) },
      renderIcon: () => <FiEdit2 title="Edit" size="18px" />,
    },
    {
      label: 'Change Status',
      onClick: ({ row }) => { handleChangeStatusClick(row.data.VIN) },
      renderIcon: () => <CgArrowsExchangeV title="Change Status" size="18px" />,
    },
    {
      label: 'Remove',
      onClick: ({ row }) => { handleDeleteClick(row.data.VIN) },
      renderIcon: () => <MdDelete size="18px" title="Remove" />,
    }
  ];

  if (spinner) {
    return (
      <div className="spinner">
        <Spinner size={92} />
      </div>
    )
  }
  return (
    <>
      <Modals
        type={modalProps.type}
        status={modal}
        toggler={st => setModal(st)}
        cancel={modalProps.cancel}
        confirm={modalProps.confirm}
        text={modalProps.text}
        title={modalProps.title}
      />
      <div className="list-container">
        <div style={{ marginBottom: 50 }}>
          {userGrp !== "FASTLevel4" && <div style={{ float: 'left' }}>
            <Link to="/">
              <UIButtonNav>
                Back
              </UIButtonNav>
            </Link>
          </div>}
          {userGrp !== "FASTLevel4" && <div style={{ float: 'right' }}>
            <Link to="/add-vehicle">
              <UIButtonNav>
                Add Vehicle
            </UIButtonNav>
            </Link>
          </div>}
        </div>
        <StatefulDataTable
          rowActions={userGrp !== "FASTLevel4" && rowActions}
          columns={columns}
          rows={rows.length > 0 ? rows : []}
          emptyMessage="No Data Found"
          rowHeight={44}
        />
      </div>
    </>
  );
};