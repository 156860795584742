import * as React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";

export default ({cancel, confirm, text, title, status, toggler, type}) => {
  return (
    <Modal
      onClose={() => toggler(false)}
      closeable
      isOpen={status}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {text}
      </ModalBody>
      <ModalFooter>
        <ModalButton kind={ButtonKind.tertiary} onClick={cancel.func}>
          {cancel.text}
        </ModalButton>
        <ModalButton onClick={confirm.func}>{confirm.text}</ModalButton>
      </ModalFooter>
    </Modal>
  );
}