import { toast } from "react-toastify";
import {API, Auth, graphqlOperation} from 'aws-amplify';

import { ClearStates } from "./Form";
import awsConfigSettings from '../aws-exports';
import { createFastMember, createFastTerminal, createFastVehicle, updateFastCustomer } from "../graphql/mutations";
import { uploadPic } from "./Uploads";
import { listFastTerminals } from "../graphql/queries";
import { CognitoIdentityServiceProvider } from "aws-sdk";

export const CreateMember = async (props, input, arr,varified="false") => {
  let userPoolId = awsConfigSettings.aws_user_pools_id;
  let phoneNo = input.phone;
  phoneNo = phoneNo.replace("(", "");
  phoneNo = phoneNo.replace(")", "");
  phoneNo = phoneNo.replace("-", "");
  phoneNo = phoneNo.replace(" ", "");
  phoneNo = "+1" + phoneNo;
  var params = {
    UserAttributes: [
      {
        Name: "email" /* required */,
        Value: ["", null, undefined].includes(input.email) ? "" : input.email
      },
      {
        Name: "phone_number" /* required */,
        Value: ["", null, undefined].includes(phoneNo) ? "" : phoneNo
      },
      {
        Name: "phone_number_verified" /* required */,
        Value: varified
      },
      {
        Name: "email_verified" /* required */,
        Value: varified
      }
    ],
    UserPoolId: userPoolId,
    Username: input.username,

    DesiredDeliveryMediums: ["SMS"] ,
    ForceAliasCreation: true,
    TemporaryPassword: input.password
  };

  let credentials = await Auth.currentCredentials();
  let client = await Promise.resolve(
    new CognitoIdentityServiceProvider({
      apiVersion: "2016-04-18",
      credentials: Auth.essentialCredentials(credentials),
      region: "us-east-2"
    })
  );
  await client.adminCreateUser(params, async function(err, data) {
    if (err) {
      toast.error(err.message);
    } else {
      try {
        let temp = input.groupsAssigned[0];
        let groupName = 
          temp === "FastAdminLevel2" ? 
            'FASTLevel2' : 
              temp === "FastServiceLevel3" ? 
                'FASTLevel3' : 
                  temp === "FastServiceLevel4" && 'FASTLevel4';
        
        await client.adminSetUserPassword({
            UserPoolId: userPoolId /* required */,
            Password: input.password,
            Username: input.username /* required */,
            Permanent: true
        }).promise();

        await client
          .adminAddUserToGroup({
            GroupName: groupName,
            UserPoolId: userPoolId,
            Username: data.User.Username
          }).promise();
          
          input.id = input.username;
          delete input.username;
          delete input.password;
          await API.graphql(graphqlOperation(createFastMember, { input }))
          .then(() => {
            ClearStates(arr);
            toast.success('Member Created');
            props.history.push('/members');
          })
        } catch(err) {
          toast.error('Failed');
        }
    }
    
  })
}

export const CreateTerminal = async (props, input, arr) => {
  let mainPic = null;
  try {
    mainPic = await uploadPic(input.terminalPhoto);
    if(mainPic) {
      input.terminalPhoto = mainPic;
    } else {
      delete input.terminalPhoto;
    }
    let makeDefault = false;
    await API.graphql(graphqlOperation(listFastTerminals, 
          {
            limit : 100, 
            filter: {customerId: {eq: input.customerId} }
          }
        )).then(res => {
          if([null, undefined].includes(res.data.listFastTerminals) || res.data.listFastTerminals.items.length === 0) {
            makeDefault = true;
          }
        })
    if(input.isDefault) {
      makeDefault = true;
    }
    await API.graphql(graphqlOperation(createFastTerminal, { input }))
      .then(async res => {
        if(makeDefault) {
          await API.graphql(graphqlOperation(updateFastCustomer, 
              {input: {id: input.customerId, defaultTerminalId: res.data.createFastTerminal.id}}
          ))
        }
        ClearStates(arr)
        if(props.location.state !== undefined && props.location.state.fromVehicle){
          props.history.push('/add-vehicle');
        } else props.history.push('/terminals');
        toast.success('Terminal Created');
      })
      .catch((error) => {
    console.log(error)

        toast.error('Failed');
      });
  } catch (error) {

    toast.error('Failed');
  }
}

export const CreateVehicle = async (props, input, arr) => {
  let mainPic = null;
  try {
    mainPic = await uploadPic(input.photo);
    if(mainPic) {
      input.photo = mainPic;
    } else {
      delete input.photo;
    }
    await API.graphql(graphqlOperation(createFastVehicle, { input }))
    .then(()=> {
      ClearStates(arr);
      props.history.push('/vehicles');
      toast.success('Vehicle Added');
    })
    .catch(() => {
      toast.error('Failed');
    })
  } catch (error) {
    toast.error('Failed');
  }
}
