import React from 'react';

import CustomHeader from '../../components/Header';
import '../../styles/index.css';
import TerminalsList from './components/TerminalsList';

const index = (props) => {
    return (
        <div>
            <CustomHeader  props={props}/>
            <div className="container">
                <TerminalsList props={props} /> 
            </div>
        </div>
    )
}

export default index
