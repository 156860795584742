import React, { useState } from 'react';
import {
  Card,
  StyledBody,
  StyledAction,
} from "baseui/card";
import { FormControl } from 'baseui/form-control';
import { Input, MaskedInput } from 'baseui/input';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
// import NumberFormat from "react-number-format";
import { Paragraph2 } from 'baseui/typography';
import Map from '../../../components/Map';
import { InputValidation, setValue, SubmitValidation } from '../../../utils/Form';
import { setSignUp } from '../../../utils/Auth';
import '../../../styles/index.css';
import { UIButtonForm } from '../../../components/UIComponents';
import Autocomplete from "react-google-autocomplete";
import { Spinner } from "baseui/spinner";


export default ({ props }) => {
  const [spinner, setSpinner] = React.useState(false);

  const [carrierName, setCarrierName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [focus, setFocus] = useState(false);
  const [dotNum, setDotNum] = useState('');
  const [errors, setErrors] = useState({
    carrierName: '',
    username: '',
    password: '',
    confirmPassword: '',
    phone: '',
    email: '',
    address: '',
    dotNum: '',
    form: ''
  });

  const handleFocus = flag => {
    setFocus(flag);
    setErrors({
      ...errors,
      form: ''
    })
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    const formValid =
      SubmitValidation(
        [
          { id: 'email', value: email },
          { id: 'phone', value: phone },
          { id: 'address', value: address },
          { id: 'password', value: password },
          { id: 'username', value: username },
          { id: 'dotNum', value: dotNum },
          { id: 'carrierName', value: carrierName }
        ],
        errors,
        focus,
        setErrors
      );
    if (!formValid) {
      document.getElementById('form-error').scrollIntoView();
      return;
    } else {
      let userInfo = {
        id: null,
        username,
        password,
        carrierName,
        address,
        phone,
        email,
        dotNum
      }
      setSignUp(setSpinner, props, userInfo);
    }
  }

  return (

    spinner ? <div className="spinner">
      <Spinner size={92} />
    </div> : <Card
      title="Register Yourself"
      id="form-error"
      className="form-card"
    >
      <StyledBody >
        <div className="form-error-container">
          {errors.form && (
            <text
              className="form-error"
            >
              {" "}
              {errors.form}
            </text>
          )}
        </div>

        <div>
          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <FormControl label="Carrier Name" error={errors.carrierName}>
                <Input
                  overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                  value={carrierName}
                  required
                  onFocus={() => handleFocus(true)}
                  onChange={e => setValue('carrierName', e.target.value, setCarrierName, setErrors, errors)}
                  onBlur={() => InputValidation('carrierName', [carrierName], errors, setErrors)}
                  error={errors.carrierName}
                  placeholder="Enter Carrier Name"
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <FormControl label="DOT" error={errors.dotNum}>
                <Input
                  overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                  value={dotNum}
                  required
                  onFocus={() => handleFocus(true)}
                  onChange={e => setValue('dotNum', e.target.value, setDotNum, setErrors, errors)}
                  onBlur={() => InputValidation('dotNum', [dotNum], errors, setErrors)}
                  error={errors.dotNum}
                  placeholder="DOT #"
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>

          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <FormControl label="Username" error={errors.username} >
                <Input
                  overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                  value={username}
                  required
                  onFocus={() => handleFocus(true)}
                  onChange={e => {
                    setValue('username', e.target.value, setUsername, setErrors, errors)
                    InputValidation('username', [e.target.value], errors, setErrors)
                  }}
                  onBlur={() => InputValidation('username', [username], errors, setErrors)}
                  error={errors.username}
                  placeholder="Enter Username"
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>

          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <FormControl label="Password" error={errors.password} >
                <Input
                  overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                  value={password}
                  required
                  onFocus={() => handleFocus(true)}
                  onChange={e => setValue('password', e.target.value, setPassword, setErrors, errors)}
                  onBlur={() => InputValidation('password', [password], errors, setErrors)}
                  error={errors.password}
                  type="password"
                  placeholder="Enter Password"
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>

          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>

              <FormControl
                label="Street Address"
                error={errors.address}>

                <Autocomplete
                  apiKey={'AIzaSyCjDtbSM_fk8MM9pHkEXZqMf4ijQhRWJoM'}
                  style={{ width: "100%", backgroundColor: 'white', border: '2px solid rgb(238, 238, 238)', padding: 10 }}
                  onChange={e => setAddress(e.target.value)}
                  onPlaceSelected={(place) => setAddress(place.formatted_address)}
                  options={{
                    types: ["(regions)"],
                    componentRestrictions: { country: "us" },
                  }}
                  value={address}
                  onBlur={e => {
                    setAddress(e.target.value)
                  }}
                  required
                  error={errors.address}
                  placeholder="Enter Street Address"
                />

                {/* <Input
            overrides={{ Input: { style: { backgroundColor: 'white' } } }}
            value={address}
            required
            onFocus={() => handleFocus(true)}
            onChange={e => setValue('address', e.target.value, setAddress, setErrors, errors)}
            onBlur={() => InputValidation('address', [address], errors, setErrors)}
            error={errors.address}
            placeholder="Enter Street Address"
          /> */}
              </FormControl>
            </FlexGridItem>

          </FlexGrid>

          <FlexGrid
            flexGridColumnCount={[1, 2]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <FormControl label="Phone" error={errors.phone} >
                <MaskedInput
                  overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                  placeholder="Phone number"
                  mask="(999) 999-9999"
                  min={10}
                  required
                  value={phone}
                  error={errors.phone}
                  onFocus={() => handleFocus(true)}
                  onChange={e => setValue('phone', e.target.value, setPhone, setErrors, errors)}
                  onBlur={() => InputValidation('phone', [phone], errors, setErrors)}
                />
              </FormControl>
            </FlexGridItem>
            <FlexGridItem>
              <FormControl label="Email" error={errors.email}>
                <Input
                  overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                  value={email}
                  required
                  onFocus={() => handleFocus(true)}
                  onChange={e => setValue('email', e.target.value, setEmail, setErrors, errors)}
                  onBlur={() => InputValidation('email', [email], errors, setErrors)}
                  error={errors.email}
                  type="email"
                  placeholder="Enter Email Address"
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <Paragraph2>
                I confirm that DOT # is valid and that CALBIT retains the right to review this account at any time and delete from the database if the information entered does not match the Safety and Finess Electronic Records (SAFER) System
              </Paragraph2>
            </FlexGridItem>
          </FlexGrid>
        </div>
      </StyledBody>
      <StyledAction>
        <FlexGrid
          flexGridColumnCount={[1, 1, 2]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem>
            <UIButtonForm color="black" bgcolor="white" onClick={() => props.history.push('/sign-in')}>
              Cancel
            </UIButtonForm>
          </FlexGridItem>
          <FlexGridItem>
            <UIButtonForm onClick={submitHandler}>
              I Confirm DOT #
            </UIButtonForm>
          </FlexGridItem>
        </FlexGrid>
      </StyledAction>
    </Card>
  )
};