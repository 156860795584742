import React from 'react';
import CustomHeader from '../../components/Header';
import VehiclesList from './components/VehiclesList_v1';
import '../../styles/index.css';

const index = (props) => {
    return (
        <div>
            <CustomHeader props={props}/>
            <div className="container">
                <VehiclesList props={props}/> 
            </div>
        </div>
    )
}

export default index
