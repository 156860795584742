import * as React from "react";
import { Select, SIZE, TYPE } from "baseui/select";
import { API, graphqlOperation } from "aws-amplify";
import { getFastVehicle } from "../../../graphql/queries";
import moment from 'moment'
export default ({
    selected,
     vehiclesList, 
     setSelected, 
     setVehicleInfo, 
     setIsLoading, 
     isLoading,
     selectedTerminal,
     moveable,
     setFilledBoxes,
     setStickerStatus
  }) => {

  const [options, setOptions] = React.useState([]);
  React.useEffect(() => {
    let temp = [];
    vehiclesList.map(vl => {
        if(selectedTerminal && 
            (
              vl.terminalId === selectedTerminal[0].id 
              || 
              selectedTerminal[0].id === "ALL"
            )) {
                temp.push({id: vl.id, label: vl.vehicleId});
              }
        return null;
    });
    setOptions(temp);
    }, [vehiclesList, selectedTerminal]);

    const ChangeHandler = async params => {
        setIsLoading(true);
        if(params.type === "select"){
            const {id} = params.value[0];
            if(id !== null){
                await API.graphql(graphqlOperation(getFastVehicle, {id})).then(data => {
                    let temp = data.data.getFastVehicle;
                    if(temp.inspectionHistory && temp.inspectionHistory.length > 0) {
                      temp.inspectionHistory.sort((a, b) => (
                        moment(new Date(b.inspectionDate)) - moment(new Date(a.inspectionDate))
                      ))
                    }
                    if(temp.inspectionHistory && temp.inspectionHistory.length > 0){
                      let days = -1 * (moment(new Date(temp.inspectionHistory[0].inspectionDate)).diff(new Date(), 'days') +1);
                      if(days > 365) {
                        setStickerStatus(false);
                      } else {
                        setStickerStatus(true);
                      }
                    }
                    let temArr = temp.inspectionHistory;
                    let filled = [];
                    temArr && temArr.length > 0 && temArr.forEach(ta => {
                      filled.push(ta.placement);
                    })
                    
                    setFilledBoxes(filled);
                    setIsLoading(false);
                    setVehicleInfo(temp);
                    setSelected(params.value);
                });
            }
        } else {
            setSelected([]);
            setIsLoading(false);
            setVehicleInfo(null);
            setFilledBoxes([])
        }
    }
    
  return (
    <Select
      options={options}
      value={selected}
      type={TYPE.select}
      size={SIZE.mini}
      isLoading={isLoading}
      disabled={isLoading || moveable}
      placeholder="Select Vehicle"
      onChange={params => ChangeHandler(params)}
      overrides={{
        Root: {
          style: {backgroundColor: "transparent"}}
        }}
    />
  );
}