import React from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'baseui/modal';
import { Table } from 'reactstrap'
const TerminalModal = ({ open, onClose, data, action, terminal, ...props }) => {
    return <Modal
        isOpen={open}
        onClose={() => onClose(false)}
        overrides={{
            Dialog: {
                style: {
                    borderRadius: '5px !important',
                },

            },
        }}
    >
        <ModalHeader>
            <div>{terminal.terminalName}</div>
            <div>{terminal.terminalAddress}</div>
            {/* <div>
                Terminal Vehicle List
            </div> */}

        </ModalHeader>
        <ModalBody >
            <Table>
                <thead>
                    <th>Vehicle Id</th>
                    <th>Vehicel Type</th>
                    <th></th>
                </thead>
                <tbody>
                    {
                        data.map(item => {
                            return <tr>
                                <td>{item.vehicleId}</td>
                                <td>{item.type}</td>
                                <td
                                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                    onClick={() => action(item)}
                                >
                                    Open Sticker
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        </ModalBody>

    </Modal>
}
export default TerminalModal