import { Auth, API, graphqlOperation } from "aws-amplify";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import { toast } from "react-toastify";
import { createFastCustomer } from "../graphql/mutations";
import { getFastCustomer, getFastMember } from "../graphql/queries";

export const getUserLocal = () => {
  return JSON.parse(localStorage.getItem('@userInfo'));
}

export const getUserNameLocal = () => {
  return JSON.parse(localStorage.getItem('@loggedUser'));
}

const getAuthCustomer = async (username, group) => {
  if (["FASTLevel2", "FASTLevel3", "FASTLevel4"].includes(group)) {
    let data = null;
    await API.graphql(
      graphqlOperation(
        getFastMember, { id: username }
      )
    ).then(async res => {
      await API.graphql(
        graphqlOperation(
          getFastCustomer,
          { id: res.data.getFastMember.customerId }
        )
      ).then(res => {
        data = res.data.getFastCustomer;
        localStorage.setItem('@companyName', JSON.stringify(data.carrierName))
      });
    });
  } else if (['FASTCustomer'].includes(group)) {
    let data = null;
    await API.graphql(
      graphqlOperation(
        getFastCustomer, { id: username }
      )
    ).then(res => {
      data = res.data.getFastCustomer;
      localStorage.setItem('@companyName', JSON.stringify(data.carrierName))
    });
  }
}

export const getUserCognito = async (status, spin, props) => {
  if (spin) spin(true);
  await Auth.currentAuthenticatedUser()
    .then(user => {
      if (user) {
        if (status) status(true)
      }
    })
    .catch(() => {
      if (spin) {
        localStorage.clear();
        props.history.push('/sign-in')
      };
      if (status) status(false)
    })
  if (spin) spin(false);
};

export const setSignOut = async () => {
  await Auth.signOut()
    .then(async () => {
      await localStorage.removeItem('@loggedUser');
      await localStorage.removeItem('@loggedUserGroup');
      await localStorage.removeItem('@companyName');
      window.location.reload(false);
    })
    .catch(() => {
      toast.error('Signing Out Failed')
    })
};

export const setConfirmation = async (code, spin, props) => {
  const {
    username,
    password,
    carrierName,
    dotNum,
    address,
  } = await JSON.parse(localStorage.getItem('@userInfo'));

  try {
    spin(true);
    await Auth.confirmSignUp(username, code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true
    })
      .then(async () => {
        await Auth.signIn(username, password).then(
          () => {
            Promise.resolve(Auth.currentAuthenticatedUser()).then(user =>
              Auth.currentCredentials()
                .then(credentials =>
                  Promise.resolve(
                    new CognitoIdentityServiceProvider({
                      apiVersion: "2016-04-18",
                      credentials: Auth.essentialCredentials(credentials),
                      region: "us-east-2"
                    })
                  )
                )
                .then(async client => {
                  await client
                    .adminAddUserToGroup({
                      GroupName: 'FASTCustomer',
                      UserPoolId: user.pool.userPoolId,
                      Username: user.username
                    })
                    .promise();
                })
                .then(async () => {
                  let input = {
                    id: username,
                    carrierName,
                    DOTNumber: dotNum,
                    carrierAddress: address
                  }
                  await API.graphql(graphqlOperation(createFastCustomer, { input }))
                    .then(async () => {
                      await Auth.signIn(username, password).then(async () => {
                        await localStorage.setItem('@companyName', JSON.stringify(input.carrierName))
                        await localStorage.setItem('@loggedUser', JSON.stringify(username));
                        await localStorage.setItem('@loggedUserGroup', JSON.stringify("FASTCustomer"));
                        window.location.reload(false);
                        // props.history.push('/');
                      })
                    })
                    .catch(() => { })
                  spin(false);
                })
                .catch(() => {
                  spin(false)
                })
            );
          }
        );
      })
      .catch(() => {
        toast.error('Invalid Code, Try Again')
        spin(false);
      });
  } catch (e) {
    spin(false);
  }
}

export const setSignIn = (spin, props, username, userPassword) => {
  try {
    spin(true);
    Auth.signIn(username, userPassword)
      .then(res => {
        if (res.signInUserSession.idToken.payload["cognito:groups"] !== undefined) {
          localStorage.setItem('@loggedUserGroup',
            JSON.stringify(
              res.signInUserSession.idToken.payload["cognito:groups"][0]
            ))
          // toast.success('Logged In');
          getAuthCustomer(username, res.signInUserSession.idToken.payload["cognito:groups"][0])
            .then(() => {
              localStorage.setItem('@loggedUser', JSON.stringify(username))
              window.location.reload();
              spin(false);
            })
        }
      })
      .catch((error) => {
        console.error(error)
        toast.error(error.message);
        spin(false);
      })
  } catch (error) {
    console.error(error)
    toast.error('Failed');
    spin(false);
  }
}

export const setSignUp = async (spin, props, userInfo) => {
  try {
    spin(true);
    let phoneNum = userInfo.phone;
    phoneNum = phoneNum.replace("(", "");
    phoneNum = phoneNum.replace(")", "");
    phoneNum = phoneNum.replace("-", "");
    phoneNum = phoneNum.replace(" ", "");
    phoneNum = "+1" + phoneNum;
    userInfo.phone = phoneNum;
    const res = await Auth.signUp({
      username: userInfo.username,
      password: userInfo.password,
      attributes: {
        phone_number: phoneNum
      }
    });
    userInfo.id = res.userSub;
    localStorage.setItem('@userInfo', JSON.stringify(userInfo));
    props.history.push('/confirmation');
    toast.success('Verification code has been sent on your phone');
    spin(false);
  } catch (err) {
    if (err.code === 'UsernameExistsException') {
      toast.error('User name already exist');
    } else {
      toast.error(err.message);
    }
    spin(false);
  }
}

export const forgotPassword = async (username) => {
  try {
   let res=await Auth.forgotPassword(username);
   console.log(res)
  } catch (error) {
    console.error(error)
    toast.error(error.message)
    throw error
  }
}

export const forgotPasswordSubmit = async (username, code, password) => {
  try {
    await Auth.forgotPasswordSubmit(
      username,
      code,
      password
    );
  } catch (error) {
    toast.error(error.message)
    throw error
  }
}