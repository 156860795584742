const testArr = [null, undefined, ""];

const EmailValidation = (email) => {
    const re = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (re.test(email)) return true;
    else return false
};

const PhoneValidation = (phone) => {
    const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (re.test(phone)) {
        return true;
    } else return false;
}

export const InputValidation = (state, values, prevErrs, errFunc) => {
    if (values[0].length <= 0 || testArr.includes(values[0])) {
        switch (state) {
            case 'address':
                errFunc({
                    ...prevErrs,
                    [state]: "Select Valid Street Address"
                })
                break;
            default:
                errFunc({
                    ...prevErrs,
                    [state]: "This Field is Required"
                })
                break;
        }
    } else if (values[0].length > 0) {
        switch (state) {
            case 'username': {
                let reg = new RegExp(/^[a-z0-9.]+$/)
                if (!reg.test(values[0])) {
                    errFunc({
                        ...prevErrs,
                        [state]: "Not Valid, Only Latters (a-z), Numbers (0-9), and Periods(.) are allowed"
                    })
                }
                break;
            }
            case 'carrierName':
                // if(values[0].length < 4) {
                //     errFunc({
                //         ...prevErrs,
                //         [state]: "Atleast 4 Characters Required"
                //     })
                // }
                break;
            case 'email':
                if (!EmailValidation(values[0])) {
                    errFunc({
                        ...prevErrs,
                        [state]: "Email Address Not Valid"
                    })
                }
                break;
            case 'password':
                if (values[0].length < 6) {
                    errFunc({
                        ...prevErrs,
                        [state]: "Password be Atleast 6 Characters"
                    })
                }
                break;
            case 'confirmPassword':
                if (values[0] !== values[1]) {
                    errFunc({
                        ...prevErrs,
                        [state]: "Password Doesn't Match"
                    })
                }
                break;
            case 'phone':
                if (!PhoneValidation(values[0])) {
                    errFunc({
                        ...prevErrs,
                        [state]: "Phone Number Not Valid"
                    })
                }
                break;
            case 'dotNum':
                break;
            case 'VIN':
                let valid = new RegExp("[A-HJ-NPR-Z0-9]{17}").test(values[0]);
                if (values[0].length < 17 || !valid) {
                    errFunc({
                        ...prevErrs,
                        [state]: !valid ?
                            "VIN Not in Correct Form" :
                            "VIN Must be 17 Characters"
                    })
                }
                break;
            case 'code':
                if (values[0].length < 6) {
                    errFunc({
                        ...prevErrs,
                        [state]: "Enter 6 Digit Code"
                    })
                }
                break;
            default:
                break;
        }

    }
};

export const SubmitValidation = (states, errors, isFocused, setErrors) => {
    let isValid = true;
    let prob = [];
    states.map(st => {
        if (st !== null) {
            if (errors[st.id].length > 0) {
                isValid = false;
            }
            if (st.value.length <= 0) {
                prob.push(st.id);
                isValid = false;
            }
        }
        return null;
    });
    if (!isFocused || !isValid) {
        if (prob.length <= 0) {
            setErrors({
                ...errors,
                form: !isFocused ? 'Please Fill the Form' : 'Form not Valid'
            });
        } else {
            prob.map(p => {
                console.log(p)
                setErrors({
                    ...errors,
                    [p]: 'This Field is Required',
                    form: 'Form not Complete, Check Errors'
                });
                return null;
            })
        }
        return false;
    } else return true;
}

export const ClearStates = (funcs) => {
    funcs.map(f => f(''))
}

export const setValue = (state, value, setFunc, errFunc, err) => {
    if (state === "username") {
        // let val = value.toLowerCase();
        // val = val.replace(" ","");
        setFunc(value);
    } else setFunc(value);
    errFunc({
        ...err,
        [state]: ''
    })
}