import * as React from "react";
import {
    Modal,
    ModalBody,
    SIZE,
    ROLE, ModalHeader
  } from "baseui/modal";
import '../../../styles/index.css';
import ViewInspectionData from "./ViewInspectionData";
import { MdDelete } from "react-icons/md";
import { H4 } from "baseui/typography";

export default ({viewOpen, setViewOpen,selectedVehicle, selectedDate, deleteHandler}) => {
    
  return (

    <Modal
      onClose={() => setViewOpen(false)}
      closeable
      isOpen={viewOpen}
      animate
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>
      <div style={{display: "flex", justifyContent: 'center'}}>
        <div>
          <H4>Inspection Date: {selectedDate.inspectionDate}</H4>
        </div>
        <div style={{paddingTop: '4px', paddingLeft: '12px'}}>
          <MdDelete 
            title="Remove" 
            size={25}
            onClick={() => deleteHandler(selectedDate)} 
            style={{
              opacity: '0.55',
              cursor: 'pointer'
            }}
            />
        </div>
      </div>
        
      </ModalHeader>
      <ModalBody>
          {/* <div className="container"> */}
              <ViewInspectionData
                  data={selectedDate}
              />
          {/* </div> */}
      </ModalBody>
    </Modal>
  );
}

