import React, { useEffect, useState } from 'react';
import {
  Card,
  StyledBody,
  StyledAction,
} from "baseui/card";
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { MaskedInput } from 'baseui/input';
import { Select } from "baseui/select";
import { Spinner } from 'baseui/spinner';
import { API, graphqlOperation } from 'aws-amplify';
// Files
import { InputValidation, setValue, SubmitValidation } from '../../../utils/Form';
import { CreateMember } from '../../../utils/Create';
import { UpdateMember } from '../../../utils/Updates';
import '../../../styles/index.css';
import { getFastMember } from '../../../graphql/queries';
import { StatefulTooltip } from "baseui/tooltip";
import { UIButtonForm } from '../../../components/UIComponents';

const PermissionsOptionsCust = [
  // { label: "Fast Customer", id: "FastCustomer" },
  { label: "Admin Level 2", id: "FastAdminLevel2", msg: 'Add Remove Team Members, Full Fleet Administration' },
  { label: "Service Level 3", id: "FastServiceLevel3", msg: 'Full Fleet Administrsation' },
  { label: "Service Level 4", id: "FastServiceLevel4", msg: 'Partial Fleet Administration' }
];

const PermissionsOptionsAdmin = [
  { label: "Service Level 3", id: "FastServiceLevel3", msg: 'Full Fleet Administrsation' },
  { label: "Service Level 4", id: "FastServiceLevel4", msg: 'Partial Fleet Administration' }
];

const usrGrp = JSON.parse(localStorage.getItem('@loggedUserGroup'));
export default ({ props }) => {
  const [memberName, setMemberName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [focus, setFocus] = useState(false);
  const [mid, setMid] = useState('');
  const [errors, setErrors] = useState({
    memberName: '',
    username: '',
    password: '',
    phone: '',
    email: '',
    terminal: '',
    permissions
  });
  const [edit, setEdit] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [btnSpinner, setBtnSpinner] = useState(false)

  const getMemberInfo = React.useCallback(async (mid) => {
    setSpinner(true);
    let res = await API.graphql(graphqlOperation(getFastMember, { id: mid }));
    res = res.data.getFastMember;
    if (res) {
      setEdit(true);
      setMemberName(res.memberName ? res.memberName : '')
      setUsername(res.memberName ? res.memberName.toLowerCase() : '')
      setPhone(res.phone ? res.phone : '')
      setEmail(res.email ? res.email : '')
      if (PermissionsOptionsCust.length > 0 && res.groupsAssigned !== null && res.groupsAssigned.length > 0) {
        let temp = [];
        PermissionsOptionsCust.map(t => {
          if (res.groupsAssigned.includes(t.id)) {
            temp.push({ id: t.id, label: t.label })
          }
          setPermissions(temp);
          return null;
        })
      }
    } else {
      props.history.push('/members');
    }
    setSpinner(false);
  }, [props.history])

  useEffect(() => {
    let mid = props.match.params && props.match.params.mid ? props.match.params.mid : null;
    if (mid) {
      setMid(mid);
      getMemberInfo(mid);
    }
  }, [getMemberInfo, props.match.params])

  const handleFocus = flag => {
    setFocus(flag);
    setErrors({
      ...errors,
      form: ''
    })
  }

  const submitHandler = async () => {
    const formValid =
      SubmitValidation(
        [
          { id: 'permissions', value: permissions },
          { id: 'email', value: email },
          { id: 'phone', value: phone },
          !edit ? { id: 'password', value: password } : null,
          { id: 'username', value: username },
          { id: 'memberName', value: memberName }
        ],
        errors,
        focus,
        setErrors
      );
    if (!formValid) {
      document.getElementById('form-error').scrollIntoView();
      return;
    } else {
      setBtnSpinner(true)
      let customerId = await JSON.parse(localStorage.getItem('@loggedUser'));
      const userGrp = JSON.parse(localStorage.getItem('@loggedUserGroup'));
      if (userGrp !== "FASTCustomer") {
        await API.graphql(
          graphqlOperation(
            getFastMember, { id: customerId }
          )
        ).then(res => {
          customerId = res.data.getFastMember.customerId;
        });
      }
      let groupsAssigned = [];
      permissions.map(p => {
        groupsAssigned.push(p.id);
        return null;
      });
      let input = {
        customerId,
        memberName,
        phone,
        email,
        groupsAssigned,
        username,
        password
      };
      if (!edit) await CreateMember(props, input, [
        setMemberName,
        setUsername,
        setPassword,
        setPhone,
        setEmail,
        setPermissions
      ], "true");
      else if (edit) {
        input.id = mid;
        await UpdateMember(props, input, [
          setMemberName,
          setUsername,
          setPassword,
          setPhone,
          setEmail,
          setPermissions
        ]);
      }
      setBtnSpinner(false)
    }
  }

  return spinner ? (<Spinner size={92} />) : (
    <Card
      overrides=
      {{
        Title: { style: { display: "flex", justifyContent: "center", paddingBottom: "20px" } }
      }}
      title={edit ? "Update Member" : "Add Member"}
      id="form-error"
      className="form-card"
    >
      <StyledBody >
        <div className="form-error-container">
          {errors.form && (
            <text
              className="form-error"
            >
              {" "}
              {errors.form}
            </text>
          )}
        </div>

        <div>
          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <FormControl label="Member Name" error={errors.memberName}>
                <Input
                  overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                  value={memberName}
                  required
                  autoFocus={edit}
                  onFocus={() => handleFocus(true)}
                  onChange={e => {
                    setValue('memberName', e.target.value, setMemberName, setErrors, errors)
                  }}
                  onBlur={() => InputValidation('memberName', [memberName], errors, setErrors)}
                  error={errors.memberName}
                  placeholder="Enter Member Name"
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>

          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <FormControl label="Username" error={errors.username} >
                <Input
                  overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                  value={username}
                  required
                  disabled={edit}
                  onFocus={() => handleFocus(true)}
                  onChange={e => {
                    setValue('username', e.target.value, setUsername, setErrors, errors)
                    InputValidation('username', [e.target.value], errors, setErrors)
                  }}
                  onBlur={() => InputValidation('username', [username], errors, setErrors)}
                  error={errors.username}
                  placeholder="Enter Username"
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>

          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <FormControl label="Password" error={errors.password} >
                <Input
                  overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                  value={password}
                  required
                  disabled={edit}
                  onFocus={() => handleFocus(true)}
                  onChange={e => setValue('password', e.target.value, setPassword, setErrors, errors)}
                  onBlur={() => InputValidation('password', [password], errors, setErrors)}
                  error={errors.password}
                  type="password"
                  placeholder="Enter Password"
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>

          <FlexGrid
            flexGridColumnCount={[1, 2]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <FormControl label="Phone" error={errors.phone} >
                <MaskedInput
                  overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                  placeholder="Phone number"
                  mask="(999) 999-9999"
                  min={10}
                  required
                  value={phone}
                  error={errors.phone}
                  onFocus={() => handleFocus(true)}
                  onChange={e => setValue('phone', e.target.value, setPhone, setErrors, errors)}
                  onBlur={() => InputValidation('phone', [phone], errors, setErrors)}
                />
              </FormControl>
            </FlexGridItem>

            <FlexGridItem>
              <FormControl label="Email" error={errors.email}>
                <Input
                  overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                  value={email}
                  required
                  onFocus={() => handleFocus(true)}
                  onChange={e => setValue('email', e.target.value, setEmail, setErrors, errors)}
                  onBlur={() => InputValidation('email', [email], errors, setErrors)}
                  error={errors.email}
                  type="email"
                  placeholder="Enter Email Address"
                />
              </FormControl>
            </FlexGridItem>

          </FlexGrid>

          <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <FormControl label="Permissions" error={errors.permissions} >
                <Select
                  options={usrGrp === "FASTCustomer" ? PermissionsOptionsCust : PermissionsOptionsAdmin}
                  value={permissions}
                  onFocus={() => handleFocus(true)}
                  getValueLabel={({ option }) => {
                    return (
                      <StatefulTooltip
                        placement="right"
                        content={() => (
                          <div padding={"20px"}>
                            {option.msg}
                          </div>
                        )}
                        returnFocus
                      // autoFocus
                      >
                        {option.label}
                      </StatefulTooltip>
                    )
                  }}
                  getOptionLabel={({ option }) => {
                    return (
                      <StatefulTooltip
                        placement="right"
                        content={() => (
                          <div padding={"20px"}>
                            {option.msg}
                          </div>
                        )}
                        returnFocus
                      // autoFocus
                      >
                        {option.label}
                      </StatefulTooltip>
                    )
                  }}
                  placeholder="Select permissions"
                  onChange={e => {
                    setValue('permissions', e.value, setPermissions, setErrors, errors)
                  }}
                  // multi={true}
                  onBlur={() => InputValidation('permissions', [permissions], errors, setErrors)}
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>

          {/* <FlexGrid
            flexGridColumnCount={[1]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            <FlexGridItem>
              <FormControl label="Terminal" error={errors.terminal} >
                <Select
                  options={[
                    { label: "Terminal 1", id: "1st" },
                    { label: "Terminal 2", id: "2nd" },
                    { label: "Terminal 3", id: "3rd" },
                    { label: "Terminal 4", id: "4th" }
                  ]}
                  value={terminal}
                  onFocus={() => handleFocus(true)}
                  placeholder="Select Terminal"
                  onChange={e => setValue('terminal', e.value, setTerminal, setErrors, errors)}
                  onBlur={() => InputValidation('terminal', [terminal], errors, setErrors)}
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid> */}

        </div>
      </StyledBody>
      <StyledAction>
        <FlexGrid
          flexGridColumnCount={[1, 1, 2]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem>
            <UIButtonForm color="black" bgcolor="white" onClick={() => { props.history.push('/members') }} >
              Cancel
            </UIButtonForm>
          </FlexGridItem>

          <FlexGridItem>
            <UIButtonForm onClick={submitHandler} >
              {btnSpinner ? <Spinner size={16} /> : edit ? "Update Member" : "Add Member"}
            </UIButtonForm>
          </FlexGridItem>
        </FlexGrid>
      </StyledAction>
    </Card>
  )
};