import { Storage } from "aws-amplify";

export const uploadPic = async photo => {
    let mainPic = null;
    if (photo && (photo.name || photo.key)) {
      let key = photo.name ? await Storage.put(
        new Date().getTime() + photo.name,
        photo
      ) : await Storage.put(
        photo.key,
        photo);
      mainPic = {
        bucket: "calbitwebappuploadbucket1254-dev",
        region: "us-east-2",
        key: key.key
      };
    }
    return mainPic;
  }