import React, { useEffect, useState } from 'react'
import {
  Card,
  StyledBody,
  StyledAction,
} from 'baseui/card';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { setValue, SubmitValidation } from '../../../utils/Form';
import { getUserLocal, setConfirmation } from '../../../utils/Auth';
import { toast } from 'react-toastify';
import { UIButtonForm } from '../../../components/UIComponents';
import { Spinner } from 'baseui/spinner';

export default ({ props, spin, spinner }) => {
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [focus, setFocus] = useState(false);
  const [errors, setErrors] = useState({
    code: '',
    form: ''
  });

  useEffect(() => {
    const info = getUserLocal();
    if (info) {
      setUsername(info.username);
    } else {
      toast.error('Sign up First')
      props.history.push('/sign-up');
    }
  }, [props.history]);

  const handleFocus = flag => {
    setFocus(flag);
    setErrors({
      ...errors,
      form: ''
    })
  }

  const submitHandler = async () => {
    const formValid = SubmitValidation(
      [
        { id: 'code', value: code },
      ],
      errors,
      setErrors,
      focus
    );
    if (!formValid) {
      document.getElementById('form-error').scrollIntoView();
      return;
    } else {
      setConfirmation(code, spin, props);
    }
  }

  return (
    <Card
      overrides={{ Root: { style: { width: '40%' } } }}
      title="Confirm Sign Up"
      id="form-error"
    >
      <StyledBody>
        <div style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          {errors.form && (
            <text
              style={{
                color: "red",
                fontSize: 16,
                float: 'right'
              }}
            >
              {" "}
              {errors.form}
            </text>
          )}
        </div>
        <FormControl label="Username" >
          <Input
            overrides={{ Input: { style: { backgroundColor: 'white' } } }}
            placeholder="Enter Your Username"
            value={username}
            disabled
            onChange={event => setUsername(event.currentTarget.value)}
          />
        </FormControl>
        <FormControl label="Verification Code" error={errors.code}>
          <Input
            overrides={{ Input: { style: { backgroundColor: 'white' } } }}
            placeholder="Enter verification code "
            onFocus={() => handleFocus(true)}
            onChange={e => setValue('code', e.target.value, setCode, setErrors, errors)}
            value={code}
            disabled={spinner}
            error={errors.code}
            maxLength="6"
            min="6"
          />
        </FormControl>
      </StyledBody>
      <StyledAction>
        <UIButtonForm onClick={submitHandler} >
          {spinner ? <Spinner size={18} color="white" /> : "Confirm"}
        </UIButtonForm>
      </StyledAction>
    </Card>
  )
};
