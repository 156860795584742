import React, { useCallback, useEffect, useState } from 'react';
// Base Web-Frameworks
import {
  Card,
  StyledBody,
  StyledAction,
} from "baseui/card";
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Select } from "baseui/select";
import { API, graphqlOperation } from 'aws-amplify';
import {
  Checkbox,
  STYLE_TYPE,
  LABEL_PLACEMENT
} from "baseui/checkbox";
import { Spinner } from "baseui/spinner";
// Files
import { InputValidation, setValue, SubmitValidation } from '../../../utils/Form';
import { getFastCustomer, getFastMember, getFastVehicle, listFastTerminals, listFastVehicles } from '../../../graphql/queries';
import FileUploader from '../../../components/FileUploader';
import '../../../styles/index.css';
import { CreateVehicle } from '../../../utils/Create';
import { toast } from 'react-toastify';
import { UpdateVehicle } from '../../../utils/Updates';
import { UIButtonForm } from '../../../components/UIComponents';

const customerId = JSON.parse(localStorage.getItem('@loggedUser'));
const userGrp = JSON.parse(localStorage.getItem('@loggedUserGroup'));

export default ({ props }) => {
  const [spin, setSpin] = useState(false);
  const [edit, setEdit] = useState(false);
  const [VIN, setVIN] = useState('');
  const [vehicleId, setVehicleId] = useState('');
  const [license, setLicense] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [year, setYear] = useState('');
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [focus, setFocus] = useState(false);
  const [photo, setPhoto] = useState({});
  const [terminal, setTerminal] = useState('');
  const [terminalsList, setTerminalsList] = useState([]);
  const [outStatus, setOutStatus] = useState(false);
  const {history}=props;
  const [errors, setErrors] = useState({
    VIN: '',
    vehicleId: '',
    license: '',
    vehicleType: '',
    year: '',
    make: '',
    model: '',
    photo: '',
    terminal: '',
    form: ''
  });

  const getTerminals = useCallback(async () => {
    setSpin(true);
    let temp = customerId;
    let notOwner = ["FASTLevel2", "FASTLevel3", "FASTLevel4"].includes(userGrp);
    if (notOwner) {
      await API.graphql(
        graphqlOperation(
          getFastMember, { id: temp }
        )
      ).then(res => {
        temp = res.data.getFastMember.customerId;
      });
    }
    const data = await API.graphql(graphqlOperation(listFastTerminals, { limit: 100, filter: { customerId: { eq: temp } } }));
    if (data.data.listFastTerminals === null || data.data.listFastTerminals.items.length <= 0) {
      toast.info('No Terminal Found, Add At Least One Terminal First', { position: 'top-center' });
      history.push('/add-terminal', { fromVehicle: true });
      return
    } else {
      setTerminalsList(data.data.listFastTerminals.items);
    }
    setSpin(false);
  }, [history]);

  const getVehicleInfo = useCallback(async (vin) => {
    setSpin(true);
    const data = await API.graphql(graphqlOperation(listFastTerminals, { limit: 100, filter: { customerId: { eq: customerId } } }));
    let tempList = data.data.listFastTerminals.items
    setTerminalsList(tempList);
    let res = await API.graphql(graphqlOperation(getFastVehicle, { id: vin }));
    res = res.data.getFastVehicle;
    if (res) {
      setEdit(true);
      setVIN(vin);
      setVehicleId(res.vehicleId ? res.vehicleId : '');
      setVehicleType(res.type ? res.type : '');
      setVehicleType([{ id: res.type ? res.type.toUpperCase() : '', label: res.type ? res.type.toUpperCase() : '' }])
      setPhoto(res.photo ? res.photo : '');
      setYear(res.year ? res.year : '');
      setMake(res.make ? res.make : '');
      setModel(res.model ? res.model : '');
      setPhoto(res.photo ? res.photo : '');
      setLicense(res.license ? res.license : '');
      setOutStatus(res.isOutOfService ? res.isOutOfService : false);
      tempList.length > 0 && tempList.map(t => {
        if (t.id === res.terminalId) {
          setTerminal([{ id: t.id, label: t.terminalName }])
        }
        return null;
      })
    } else {
      history.push('/vehicles');
    }
    setSpin(false);
  }, [history])

  useEffect(() => {
    let vin = props.match.params && props.match.params.VIN ? props.match.params.VIN : null;
    if (vin !== null) {
      getVehicleInfo(vin);
    } else getTerminals();
  }, [getTerminals, props.match.params, getVehicleInfo])

  const handleFocus = flag => {
    setFocus(flag);
    setErrors({
      ...errors,
      form: ''
    })
  }

  const submitHandler = async () => {
    const formValid =
      SubmitValidation(
        [
          // {id: 'terminal', value: terminal},
          { id: 'vehicleType', value: ["", null, undefined].includes(terminal) || terminal.length <= 0 ? '' : vehicleType[0].id },
          { id: 'VIN', value: VIN },
          { id: 'vehicleId', value: vehicleId },
        ],
        errors,
        focus,
        setErrors
      );
    if (!formValid) {

      document.getElementById('form-error').scrollIntoView();
      return;
    } else {
      let terminalId = '';
      let temp = customerId;
      let notOwner = ["FASTLevel2", "FASTLevel3", "FASTLevel4"].includes(userGrp);
      if (notOwner) {
        await API.graphql(
          graphqlOperation(
            getFastMember, { id: temp }
          )
        ).then(res => {
          temp = res.data.getFastMember.customerId;
        });
      }
      if (["", null, undefined].includes(terminal) || terminal.length <= 0) {
        await API.graphql(graphqlOperation(getFastCustomer, { id: temp })).then(res => {
          let { defaultTerminalId } = res.data.getFastCustomer;
          let tempArr = terminalsList;
          tempArr = tempArr.filter(ta => ta.id === defaultTerminalId);
          terminalId = defaultTerminalId;
          toast.info(`No Terminal Selected, Setting ${tempArr[0].terminalName} as default Terminal`);
        })
      } else {
        terminalId = terminal[0].id
      }
      setSpin(true);
      const res = await API.graphql(graphqlOperation(getFastVehicle, { id: VIN }));
      const res1 = await API.graphql(graphqlOperation(listFastVehicles, { filter: { vehicleId: { eq: vehicleId } } }));
      // console.log(res1);
      if (!edit && res.data.getFastVehicle !== null) {
        toast.error('Vehicle with same VIN already exists');
        setSpin(false);
        return;
      }
      if (!edit && res1.data.listFastVehicles.items.length > 0) {
        toast.error('Vehicle with same id already exists');
        setSpin(false);
        return;
      }
      let input = {
        id: VIN,
        vehicleId,
        customerId: temp,
        terminalId,
        type: ["", null, undefined].includes(terminal) || terminal.length <= 0 ? '' : vehicleType[0].id,
        year,
        make,
        model,
        isOutOfService: outStatus,
        isDeactivated: false,
        photo
      };
      if (!edit) {
        await CreateVehicle(props, input, [
          setVIN,
          setVehicleId,
          setLicense,
          setVehicleType,
          setYear,
          setMake,
          setModel,
          setPhoto,
          setTerminal,
        ])
      } else {
        await UpdateVehicle(props, input, [
          setVIN,
          setVehicleId,
          setLicense,
          setVehicleType,
          setYear,
          setMake,
          setModel,
          setPhoto,
          setTerminal,
        ])
      }
      setSpin(false);
    }
  }


  return spin ? (
    <div className="spinner">
      <Spinner size={92} />
    </div>
  ) : (
    <Card
      overrides={{
        Title: { style: { display: "flex", justifyContent: "center", paddingBottom: "20px" } }
      }}
      className="form-card"
      title={edit ? "Update Vehicle" : "Add Vehicle"}
      id="form-error"
    >
      <StyledBody>
        <div className="form-error-container">
          {errors.form && (
            <text
              className="form-error"
            >
              {" "}
              {errors.form}
            </text>
          )}
        </div>
        <FlexGrid
          flexGridColumnCount={[1, 1,3]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem
            style={{ margin: '0 10px' }}
          >
            <FormControl label="Vehicle ID" error={errors.vehicleId}>
              <Input
                overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                value={vehicleId}
                autoFocus={edit}
                onFocus={() => handleFocus(true)}
                onChange={e => setValue('vehicleId', e.target.value, setVehicleId, setErrors, errors)}
                onBlur={() => InputValidation('vehicleId', [vehicleId], errors, setErrors)}
                error={errors.vehicleId}
                placeholder="Enter Vehicle ID"
              />
            </FormControl>
          </FlexGridItem>

          <FlexGridItem
            style={{ margin: '0 10px' }}

          >
            <FormControl label="VIN #" error={errors.VIN}>
              <Input
                overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                value={VIN}
                disabled={edit}
                maxLength="17"
                min="17"
                onFocus={() => handleFocus(true)}
                onChange={e => setValue('VIN', e.target.value, setVIN, setErrors, errors)}
                onBlur={() => InputValidation('VIN', [VIN], errors, setErrors)}
                error={errors.VIN}
                placeholder="Enter 17 Digit VIN Number"
              />
            </FormControl>
          </FlexGridItem>
        </FlexGrid>

        <FlexGrid
          flexGridColumnCount={[1, 1, 4]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem
            style={{ margin: '0 10px' }}
          >
            <FormControl label="Vehicle Type" error={errors.vehicleType} >
              <Select
                options={[
                  { id: 'TRUCK', label: 'TRUCK' },
                  { id: 'TRAILER', label: 'TRAILER' },
                  { id: 'VAN', label: 'VAN' },
                  { id: 'BUS', label: 'BUS' },
                  { id: 'TRACTOR', label: 'TRACTOR' },
                  { id: 'PICKUPTRUCK', label: 'PICKUPTRUCK' },
                ]}
                value={vehicleType}
                placeholder="Select Vehicle type"
                onChange={e => setValue('vehicleType', e.value, setVehicleType, setErrors, errors)}
                onBlur={() => InputValidation('vehicleType', [vehicleType], errors, setErrors)}
              />
            </FormControl>
          </FlexGridItem>

          <FlexGridItem
            style={{margin:'0 10px'}}
          >
            <FormControl label="License" error={errors.license}>
              <Input
                overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                value={license}
                onChange={e => setValue('license', e.target.value, setLicense, setErrors, errors)}
                // onBlur={() => InputValidation('license', [license], errors, setErrors)}
                error={errors.license}
                placeholder="Enter License Number"
              />
            </FormControl>
          </FlexGridItem>
        </FlexGrid>

        <FlexGrid
          flexGridColumnCount={[1, 1, 3]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem
            style={{margin:'0 10px'}}
          >
            <FormControl label="Year" error={errors.year} >
              <Input
                overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                value={year}
                onChange={e => setValue('year', e.target.value, setYear, setErrors, errors)}
                // onBlur={() => InputValidation('year', [year], errors, setErrors)}
                error={errors.year}
                placeholder="Vehicle Year"
              />
            </FormControl>
          </FlexGridItem>

          <FlexGridItem
            style={{margin:'0 10px'}}
          >
            <FormControl label="Make" error={errors.make} >
              <Input
                overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                value={make}
                onChange={e => setValue('make', e.target.value, setMake, setErrors, errors)}
                // onBlur={() => InputValidation('make', [make], errors, setErrors)}
                error={errors.make}
                placeholder="Vehicle Make"
              />
            </FormControl>
          </FlexGridItem>

          <FlexGridItem
            style={{margin:'0 10px'}}
          >
            <FormControl label="Model" error={errors.model} >
              <Input
                overrides={{ Input: { style: { backgroundColor: 'white' } } }}
                value={model}
                onChange={e => setValue('model', e.target.value, setModel, setErrors, errors)}
                // onBlur={() => InputValidation('model', [model], errors, setErrors)}
                error={errors.model}
                placeholder="Vehicle Model"
              />
            </FormControl>
          </FlexGridItem>

        </FlexGrid>

        <FlexGrid
          flexGridColumnCount={[1]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem
            style={{margin:'0 10px'}}
          >
            <FormControl label="Terminal" error={errors.terminal} >
              <Select
                options={terminalsList.length > 0 ? terminalsList.map(t => {
                  return { id: t.id, label: t.terminalName }
                }) : []}
                value={terminal}
                // onFocus={() => handleFocus(true)}
                placeholder="Select Terminal"
                onChange={e => setValue('terminal', e.value, setTerminal, setErrors, errors)}
                onBlur={() => InputValidation('terminal', [terminal], errors, setErrors)}
              />
            </FormControl>
          </FlexGridItem>
        </FlexGrid>

        <FlexGrid
          flexGridColumnCount={[1]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem
            style={{margin:'0 10px'}}
          >
            <FormControl label="Vehicle Status" >
              <Checkbox
                checked={outStatus}
                checkmarkType={STYLE_TYPE.toggle_round}
                onChange={e => setOutStatus(e.target.checked)}
                labelPlacement={LABEL_PLACEMENT.right}
              >
                {outStatus ? "Out Of Service" : "In Service"}
              </Checkbox>
            </FormControl>
          </FlexGridItem>
        </FlexGrid>

        <FlexGrid
          flexGridColumnCount={[1]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem overrides={{ Block: { style: { border: errors.address && "2px solid red", margin: "8px" } } }}>
            <FormControl label="Vehicle Photo">
              <FileUploader
                pic={photo}
                picFunc={setPhoto}
                multiple={false}
              />
            </FormControl>
          </FlexGridItem>

        </FlexGrid>

      </StyledBody>
      <StyledAction>
        <FlexGrid
          flexGridColumnCount={[1, 1, 2]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
        >
          <FlexGridItem>
            <UIButtonForm onClick={() => history.goBack()} color="black" bgcolor="white">
              Cancel
            </UIButtonForm>
          </FlexGridItem>

          <FlexGridItem>
            <UIButtonForm onClick={submitHandler} >
              {edit ? "Update Vehicle" : "Add Vehicle"}
            </UIButtonForm>
          </FlexGridItem>
        </FlexGrid>
      </StyledAction>
    </Card>
  )
};