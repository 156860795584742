import React from 'react'

import CustomHeader from '../../components/Header'
import '../../styles/index.css'
import AddMemberForm from './components/AddMemberForm'
import '../../styles/index.css';

const AddMember = (props) => {

    return (
        <>
            <CustomHeader  props={props}/>
            <div className="container" >
                <AddMemberForm props={props} />
            </div>
        </>
    )
}

export default AddMember
