import React from 'react';
import { Label2 } from 'baseui/typography';
import { Spinner } from 'baseui/spinner';

export const CompanyInfo = ({set, companyName, companyAddress}) => {

    return (
            [null, undefined,""].includes(companyName) && [null, undefined,""].includes(companyAddress) ?
                <Spinner size={22} />
            :
            <>
                {
                    set === "name" ? 
                        <Label2> {companyName} </Label2> : 
                    set === "address" ? 
                        <Label2> {companyAddress} </Label2> :
                            <Label2> No Data </Label2>
                }
            </>
    )
}
