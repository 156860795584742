import React, { useEffect, useState } from 'react';
import {
    Card,
    StyledBody,
} from 'baseui/card';
import { FormControl } from 'baseui/form-control';
import { InputValidation, setValue, SubmitValidation } from '../../../utils/Form';
import { Spinner } from 'baseui/spinner';
import { UIButtonForm } from '../../../components/UIComponents';
import { BiShow, BiHide } from 'react-icons/bi'
import { forgotPassword, forgotPasswordSubmit } from '../../../utils/Auth'
import { toast } from 'react-toastify';


const conformationBtn = {
    backgroundColor: 'rgb(41, 51, 119)',
    borderRadius: 5,
    color: 'white',
    width: '90%',
    padding: '10px 25px',
    textAlign: 'center',
    display: 'inline-block',
    fontSize: 16,
    margin: 0,
    cursor: 'pointer',
}


export default ({ history }) => {
    const [spinner, setSpinner] = useState(false);
    const [username, setUsername] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')

    const [focus, setFocus] = useState(false);
    const [show, setShow] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showRePassword, setReShowPassword] = useState(false)

    const [errors, setErrors] = useState({
        username: '',
        code: '',
        password: '',
        rePassword,
        form: ''
    });

    useEffect(() => {
        setShow('username')
    }, [])



    const getCode = async () => {
        setSpinner(true)
        const formValid = SubmitValidation(
            [
                { id: 'username', value: username },
            ],
            errors,
            focus,
            setErrors
        );
        if (!formValid) {
            document.getElementById('form-error').scrollIntoView();
            setSpinner(false)
            return;
        }
        try {
            await forgotPassword(username)
            setShow('code')
        } catch (err) {
            console.error(err)
        }
        setSpinner(false)
    }

    const submitHandler = async () => {
        setSpinner(true)
        if (password !== rePassword) {
            toast.error('Password Mismatch')
            return;
        }

        const formValid = SubmitValidation(
            [
                { id: 'code', value: code },
                { id: 'password', value: password },
                { id: 'rePassword', value: rePassword }
            ],
            errors,
            focus,
            setErrors
        );
        if (!formValid) {
            document.getElementById('form-error').scrollIntoView();
            return;
        }
        try {
            await forgotPasswordSubmit(username, code, password)
            history.push('/sign-in')
        } catch (error) {
            console.error(error.message)
        }
        setSpinner(false)
    }
    const handleFocus = flag => {
        setFocus(flag);
        setErrors({
            ...errors,
            form: ''
        })
    }

    return <Card
        overrides={{ Root: { style: { width: '100%', backgroundColor: '#F6F6F6', padding: '7% 5%' } } }}
        // title="Login Form"
        id="form-error"
    >
        <StyledBody>

            <div className="form-error-container">
                {errors.form && (
                    <text
                        className="form-error"
                    >
                        {" "}
                        {errors.form}
                    </text>
                )}
            </div>
            <div style={{ minHeight: 150 }}>
                {
                    show === 'username' && <>
                        <FormControl error={errors.username} >
                            <div className="input-group">
                                <input
                                    disabled={spinner}
                                    className="input-txt"
                                    placeholder="Username"
                                    onKeyDown={e => {
                                        if (e.charCode === 13 || e.keyCode === 13) {
                                            getCode();
                                        }
                                    }}
                                    value={username}
                                    onFocus={() => handleFocus(true)}
                                    onChange={e => {
                                        setValue('username', e.target.value, setUsername, setErrors, errors)
                                        InputValidation('username', [e.target.value], errors, setErrors)
                                    }}
                                    onBlur={() => InputValidation('username', [username], errors, setErrors)}
                                    error={errors.username}
                                />
                            </div>
                        </FormControl>
                        <UIButtonForm
                            onClick={() => getCode()}
                            style={conformationBtn}
                            className="confirm-btn"
                        >
                            {spinner ? <Spinner color="white" size={12} /> : "Send Confirmation Code"}
                        </UIButtonForm>
                    </>
                }

                {
                    show === 'code' && <>
                        <FormControl error={errors.code} >
                            <div className="input-group">
                                <input
                                    disabled={spinner}
                                    style={{ padding: 10 }}
                                    placeholder="Enter code"
                                    onKeyDown={e => {
                                        if (e.charCode === 13 || e.keyCode === 13) {
                                            submitHandler();
                                        }
                                    }}
                                    value={code}
                                    onFocus={() => handleFocus(true)}
                                    onChange={e => setValue('code', e.target.value, setCode, setErrors, errors)}
                                    onBlur={() => InputValidation('code', [code], errors, setErrors)}
                                    error={errors.code}
                                />
                            </div>
                        </FormControl>
                        <FormControl label="Password" error={errors.password} >
                            <div className="input-group">
                                <input
                                    className="input-pw"
                                    id="in-pw"
                                    disabled={spinner}
                                    onKeyDown={e => {
                                        if (e.charCode === 13 || e.keyCode === 13) {
                                            submitHandler();
                                        }
                                    }}
                                    placeholder="Password"
                                    type="password"
                                    value={password}
                                    onFocus={() => handleFocus(true)}
                                    onChange={e => setValue('password', e.target.value, setPassword, setErrors, errors)}
                                    onBlur={() => InputValidation('password', [password], errors, setErrors)}
                                    error={errors.password}
                                />
                                {!showPassword && <BiShow size={20} onClick={() => {
                                    var x = document.getElementById("in-pw");
                                    if (x.type === "password") {
                                        x.type = "text";
                                        setShowPassword(true)
                                    } else {
                                        x.type = "password";
                                        setShowPassword(false)
                                    }
                                }} />}
                                {showPassword && <BiHide size={20} onClick={() => {
                                    var x = document.getElementById("in-pw");
                                    if (x.type === "password") {
                                        x.type = "text";
                                        setShowPassword(true)
                                    } else {
                                        x.type = "password";
                                        setShowPassword(false)
                                    }
                                }} />}
                            </div>
                        </FormControl>

                        <FormControl label="Re Enter Password" error={errors.rePassword} >
                            <div className="input-group">
                                <input
                                    className="input-pw"
                                    id="in-rpw"
                                    disabled={spinner}
                                    onKeyDown={e => {
                                        if (e.charCode === 13 || e.keyCode === 13) {
                                            submitHandler();
                                        }
                                    }}
                                    placeholder="Re Enter Password"
                                    type="password"
                                    value={rePassword}
                                    onFocus={() => handleFocus(true)}
                                    onChange={e => setValue('rePassword', e.target.value, setRePassword, setErrors, errors)}
                                    onBlur={() => InputValidation('rePassword', [rePassword], errors, setErrors)}
                                    error={errors.rePassword}
                                />
                                {!showRePassword && <BiShow size={20} onClick={() => {
                                    var x = document.getElementById("in-rpw");
                                    if (x.type === "password") {
                                        x.type = "text";
                                        setReShowPassword(true)
                                    } else {
                                        x.type = "password";
                                        setReShowPassword(false)
                                    }
                                }} />}
                                {showRePassword && <BiHide size={20} onClick={() => {
                                    var x = document.getElementById("in-rpw");
                                    if (x.type === "password") {
                                        x.type = "text";
                                        setReShowPassword(true)
                                    } else {
                                        x.type = "password";
                                        setReShowPassword(false)
                                    }
                                }} />}
                            </div>
                        </FormControl>
                        <UIButtonForm
                            onClick={() => submitHandler()}
                            style={conformationBtn}
                        >
                            {spinner ? <Spinner color="white" size={12} /> : "Change Password"}
                        </UIButtonForm>

                    </>
                }

                {
                    show === 'password' && <>
                        <FormControl label="Password" error={errors.password} >
                            <div className="input-group">
                                <input
                                    className="input-pw"
                                    id="in-pw"
                                    disabled={spinner}
                                    onKeyDown={e => {
                                        if (e.charCode === 13 || e.keyCode === 13) {
                                            submitHandler();
                                        }
                                    }}
                                    placeholder="Password"
                                    type="password"
                                    value={password}
                                    onFocus={() => handleFocus(true)}
                                    onChange={e => setValue('password', e.target.value, setPassword, setErrors, errors)}
                                    onBlur={() => InputValidation('password', [password], errors, setErrors)}
                                    error={errors.userPassword}
                                />
                                {!showPassword && <BiShow size={20} onClick={() => {
                                    var x = document.getElementById("in-pw");
                                    if (x.type === "password") {
                                        x.type = "text";
                                        setShowPassword(true)
                                    } else {
                                        x.type = "password";
                                        setShowPassword(false)
                                    }
                                }} />}
                                {showPassword && <BiHide size={20} onClick={() => {
                                    var x = document.getElementById("in-pw");
                                    if (x.type === "password") {
                                        x.type = "text";
                                        setShowPassword(true)
                                    } else {
                                        x.type = "password";
                                        setShowPassword(false)
                                    }
                                }} />}
                            </div>
                        </FormControl>

                        <FormControl label="Re Enter Password" error={errors.password} >
                            <div className="input-group">
                                <input
                                    className="input-pw"
                                    id="in-rpw"
                                    disabled={spinner}
                                    onKeyDown={e => {
                                        if (e.charCode === 13 || e.keyCode === 13) {
                                            submitHandler();
                                        }
                                    }}
                                    placeholder="Re Enter Password"
                                    type="password"
                                    value={rePassword}
                                    onFocus={() => handleFocus(true)}
                                    onChange={e => setValue('rePassword', e.target.value, setRePassword, setErrors, errors)}
                                    onBlur={() => InputValidation('rePassword', [rePassword], errors, setErrors)}
                                    error={errors.userPassword}
                                />
                                {!showRePassword && <BiShow size={20} onClick={() => {
                                    var x = document.getElementById("in-rpw");
                                    if (x.type === "password") {
                                        x.type = "text";
                                        setReShowPassword(true)
                                    } else {
                                        x.type = "password";
                                        setReShowPassword(false)
                                    }
                                }} />}
                                {showRePassword && <BiHide size={20} onClick={() => {
                                    var x = document.getElementById("in-rpw");
                                    if (x.type === "password") {
                                        x.type = "text";
                                        setReShowPassword(true)
                                    } else {
                                        x.type = "password";
                                        setReShowPassword(false)
                                    }
                                }} />}
                            </div>
                        </FormControl>
                        <UIButtonForm
                            onClick={() => submitHandler()}
                            style={conformationBtn}
                            className="confirm-btn"
                        >
                            {spinner ? <Spinner color="white" size={12} /> : "Change Password"}
                        </UIButtonForm>
                    </>
                }
            </div>
        </StyledBody>

    </Card>
};