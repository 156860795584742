import React from 'react'
import CustomHeader from '../../components/Header'
import SignupForm from './components/SignupForm';
import '../../styles/index.css';
import { CopyRightFooter } from '../../components/UIComponents';

const SignUp = (props) => {

    return (
        <>
            <CustomHeader props={props} />
            <div className="container">
                <SignupForm
                    props={props}
                />
            </div>
            <CopyRightFooter />
        </>

    )
}

export default SignUp
