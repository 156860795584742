import Views from '../view';

const Index_View = {
    component: Views.StartView,
    isExact: true,
    path: '/',
    type: 'auth',
    viewTo: ['FASTCustomer',"FASTLevel2", "FASTLevel3"]
};

const SignUp_View = {
    component: Views.SignUp,
    isExact: true,
    path: '/sign-up',
    type: 'unauth',
    viewTo: ['FASTCustomer',"FASTLevel2", "FASTLevel3", "FASTLevel4"]
};

const SignIn_View = {
    component: Views.SignIn,
    isExact: true,
    path: '/sign-in',
    type: 'unauth',
    viewTo: ['FASTCustomer',"FASTLevel2", "FASTLevel3", "FASTLevel4"]
}

const ForgotPassword_View = {
    component: Views.ForgotPassword,
    isExact: true,
    path: '/forgot-password',
    type: 'unauth',
    viewTo: ['FASTCustomer',"FASTLevel2", "FASTLevel3", "FASTLevel4"]
}

const AddVeh_View = {
    component: Views.AddVehicle,
    isExact: true,
    path: '/add-vehicle',
    type: 'auth',
    viewTo: ['FASTCustomer',"FASTLevel2", "FASTLevel3"]
}

const EditVeh_View = {
    component: Views.AddVehicle,
    isExact: true,
    path: '/edit-vehicle/:VIN',
    type: 'auth',
    viewTo: ['FASTCustomer',"FASTLevel2", "FASTLevel3"]
}

const Veh_View = {
    component: Views.VehiclesView,
    isExact: true,
    path: '/vehicles',
    type: 'auth',
    viewTo: ['FASTCustomer',"FASTLevel2", "FASTLevel3", "FASTLevel4"]
}

const AddTerminal_View = {
    component: Views.AddTerminal,
    isExact: true,
    path: '/add-terminal',
    type: 'auth',
    viewTo: ['FASTCustomer',"FASTLevel2"]
}

const EditTerminal_View = {
    component: Views.AddTerminal,
    isExact: true,
    path: '/edit-terminal/:tid',
    type: 'auth',
    viewTo: ['FASTCustomer',"FASTLevel2"]
}

const Terminal_View = {
    component: Views.TerminalsView,
    isExact: true,
    path: '/terminals',
    type: 'auth',
    viewTo: ['FASTCustomer',"FASTLevel2"]
}

const AddMember_View = {
    component: Views.AddMember,
    isExact: true,
    path: '/add-member',
    type: 'auth',
    viewTo: ['FASTCustomer',"FASTLevel2"]
}

const EditMember_View = {
    component: Views.AddMember,
    isExact: true,
    path: '/edit-member/:mid',
    type: 'auth',
    viewTo: ['FASTCustomer',"FASTLevel2"]
}

const Member_View = {
    component: Views.MembersView,
    isExact: true,
    path: '/members',
    type: 'auth',
    viewTo: ['FASTCustomer',"FASTLevel2"]
}

const Confirmation_View = {
    component: Views.Confirmation,
    isExact: true,
    path: '/confirmation',
    type: 'unauth',
    viewTo: ['FASTCustomer',"FASTLevel2", "FASTLevel3", "FASTLevel4"]
}

export default [
    SignIn_View, 
    SignUp_View, 
    Index_View, 
    AddVeh_View, 
    Veh_View, 
    Confirmation_View,
    Terminal_View,
    AddTerminal_View,
    AddMember_View,
    Member_View,
    EditVeh_View,
    EditMember_View,
    EditTerminal_View,
    ForgotPassword_View
];
