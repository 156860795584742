/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFastCustomer = /* GraphQL */ `
  query GetFastCustomer($id: ID!) {
    getFastCustomer(id: $id) {
      id
      carrierName
      DOTNumber
      carrierAddress
      defaultTerminalId
      members {
        items {
          id
          customerId
          memberName
          phone
          email
          terminalAssignedToID
          terminalAssignedToName
          groupsAssigned
          createdAt
          updatedAt
        }
        nextToken
      }
      terminals {
        items {
          id
          customerId
          terminalName
          terminalAddress
          terminalLat
          terminalLong
          description
          isDefault
          isDeactivated
          createdAt
          updatedAt
          terminalLat
          terminalLong
        }
        nextToken
      }
      vehicles {
        items {
          id
          vehicleId
          customerId
          terminalId
          isOutOfService
          isDeactivated
          type
          year
          make
          model
          nextDueDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFastCustomers = /* GraphQL */ `
  query ListFastCustomers(
    $filter: ModelFastCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFastCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        carrierName
        DOTNumber
        carrierAddress
        defaultTerminalId
        members {
          nextToken
        }
        terminals {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFastMember = /* GraphQL */ `
  query GetFastMember($id: ID!) {
    getFastMember(id: $id) {
      id
      customerId
      customer {
        id
        carrierName
        DOTNumber
        carrierAddress
        defaultTerminalId
        members {
          nextToken
        }
        terminals {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
      }
      memberName
      phone
      email
      terminalAssignedToID
      terminalAssignedToName
      groupsAssigned
      createdAt
      updatedAt
    }
  }
`;
export const listFastMembers = /* GraphQL */ `
  query ListFastMembers(
    $filter: ModelFastMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFastMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerId
        customer {
          id
          carrierName
          DOTNumber
          carrierAddress
          defaultTerminalId
          createdAt
          updatedAt
        }
        memberName
        phone
        email
        terminalAssignedToID
        terminalAssignedToName
        groupsAssigned
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFastTerminal = /* GraphQL */ `
  query GetFastTerminal($id: ID!) {
    getFastTerminal(id: $id) {
      id
      customerId
      customer {
        id
        carrierName
        DOTNumber
        carrierAddress
        defaultTerminalId
        members {
          nextToken
        }
        terminals {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
      }
      terminalName
      terminalAddress
      terminalLat
      terminalLong
      description
      terminalPhoto {
        bucket
        region
        key
      }
      isDefault
      vehicles {
        items {
          id
          vehicleId
          customerId
          terminalId
          isOutOfService
          isDeactivated
          type
          year
          make
          model
          nextDueDate
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeactivated
      createdAt
      updatedAt
    }
  }
`;
export const listFastTerminals = /* GraphQL */ `
  query ListFastTerminals(
    $filter: ModelFastTerminalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFastTerminals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerId
        customer {
          id
          carrierName
          DOTNumber
          carrierAddress
          defaultTerminalId
          createdAt
          updatedAt
        }
        terminalName
        terminalAddress
        terminalLat
        terminalLong
        description
        terminalPhoto {
          bucket
          region
          key
        }
        isDefault
        vehicles {
          nextToken
        }
        isDeactivated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFastVehicle = /* GraphQL */ `
  query GetFastVehicle($id: ID!) {
    getFastVehicle(id: $id) {
      id
      vehicleId
      customerId
      customer {
        id
        carrierName
        DOTNumber
        carrierAddress
        defaultTerminalId
        members {
          nextToken
        }
        terminals {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
      }
      terminalId
      isOutOfService
      isDeactivated
      type
      year
      make
      model
      photo {
        bucket
        region
        key
      }
      nextDueDate
      inspectionHistory {
        inspectionDate
        attachments {
          bucket
          region
          key
        }
        timeStamp
        placement
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFastVehicles = /* GraphQL */ `
  query ListFastVehicles(
    $filter: ModelFastVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFastVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vehicleId
        customerId
        customer {
          id
          carrierName
          DOTNumber
          carrierAddress
          defaultTerminalId
          createdAt
          updatedAt
        }
        terminalId
        isOutOfService
        isDeactivated
        type
        year
        make
        model
        photo {
          bucket
          region
          key
        }
        nextDueDate
        inspectionHistory {
          inspectionDate
          timeStamp
          placement
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFastInspectionHistoryDump = /* GraphQL */ `
  query GetFastInspectionHistoryDump($id: ID!) {
    getFastInspectionHistoryDump(id: $id) {
      id
      vin
      inspectionDate
      attachments {
        bucket
        region
        key
      }
      timeStamp
      createdAt
      updatedAt
    }
  }
`;
export const listFastInspectionHistoryDumps = /* GraphQL */ `
  query ListFastInspectionHistoryDumps(
    $filter: ModelFastInspectionHistoryDumpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFastInspectionHistoryDumps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vin
        inspectionDate
        attachments {
          bucket
          region
          key
        }
        timeStamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
