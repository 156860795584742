import React from 'react';
import {
  StatefulDataTable,
  StringColumn, 
  CustomColumn
} from 'baseui/data-table';
import { Link } from 'react-router-dom';
import {Spinner} from 'baseui/spinner';
import { FiEdit2 } from 'react-icons/fi';

import { fetchTerminals } from '../../../utils/Fetch';
import '../../../styles/index.css'
import { UIButtonNav } from '../../../components/UIComponents';

export default ({props}) => {
  const [rows, setRows] = React.useState([]);
  const [spinner, setSpinner] = React.useState(false);


  React.useEffect(() => {
    fetchTerminals(setSpinner, setRows);
  }, [])

  const rowActions = [
    {
      label: 'Edit',
      onClick: ({row}) => {props.history.push(`/edit-terminal/${row.data.id}`)},
      renderIcon: () => <FiEdit2 title="Edit" size="18px" />,
    },
  ];

  const columns = [
    StringColumn({
      title: 'Name',
      mapDataToValue: (data) => data.terminalName,
    }),
    StringColumn({
      title: 'Address',
      mapDataToValue: (data) => data.terminalAddress,
    }),
    CustomColumn({
      title: '  ',
      mapDataToValue: () => "",
      renderCell : () => {
        return null;
      }
    }),
  ];

  return spinner ? 
  <div className="spinner">
    <Spinner size={92} />
  </div> : (
    <div className="list-container">
      <div style={{paddingBottom: "50px"}}>
        <div style={{float: 'left'}}>
          <Link to="/">
              <UIButtonNav>
                Back
              </UIButtonNav>
          </Link>
        </div>
        <div style={{float: 'right'}}>
          <Link to="/add-terminal">
              <UIButtonNav>
                  Add Terminal
              </UIButtonNav>
          </Link>
        </div>
      </div>
        <StatefulDataTable
          rowActions={rowActions}
          columns={columns}
          rows={rows.length > 0 ? rows : []}
          emptyMessage="No Data Found"
        />
    </div>
  );
};