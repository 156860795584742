import { API, graphqlOperation } from "aws-amplify"
import { toast } from "react-toastify"
import { deleteFastMember, deleteFastTerminal } from "../graphql/mutations"

export const DeleteTerminal = async (props, input) => {
    await API.graphql(graphqlOperation(deleteFastTerminal, {input}))
        .then(() => {
            toast.info('Terminal Deleted');
            props.history.push('/terminals')
        })
        .catch(err => {
            if(err) toast.error('Failed');
        })     
}

export const DeleteMember = async (props, input) => {
    await API.graphql(graphqlOperation(deleteFastMember, {input}))
        .then(() => {
            toast.info('Member Deleted');
            props.history.push('/members')
        })
        .catch(err => {
            if(err) toast.error('Failed');
        })     
}