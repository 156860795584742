import React from 'react';
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, BaseProvider} from 'baseui';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Main from './Main';
import Amplify from 'aws-amplify'
import awsConfig from './aws-exports'
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyCjDtbSM_fk8MM9pHkEXZqMf4ijQhRWJoM");
Geocode.enableDebug();
Amplify.configure(awsConfig)

const engine = new Styletron();

export default function App({props}) {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <Main props={props} />
      </BaseProvider>
      <ToastContainer />
    </StyletronProvider>
  );
}