import Confirmation from './Confirmation/Confirmation';
import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import StartView from './Start/Start';
import AddVehicle from './Vehicles/AddVehicle';
import VehiclesView from './Vehicles/index';
import AddTerminal from './Terminals/AddTerminal';
import TerminalsView from './Terminals/index';
import AddMember from './Members/AddMember';
import MembersView from './Members/index';
import ForgotPassword from './ForgotPassword/ForgotPassword'
export default {
    SignIn,
    SignUp,
    StartView,
    AddVehicle,
    VehiclesView,
    Confirmation,
    TerminalsView,
    AddTerminal,
    AddMember,
    MembersView,
    ForgotPassword
}