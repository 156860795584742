import * as React from "react";
import {
    Modal,
    ModalBody,
    SIZE,
    ROLE, ModalHeader
  } from "baseui/modal";

import AddInspectionForm from './AddInspectionForm';
import '../../../styles/index.css';

export default ({isOpen, 
  setIsOpen,
   selectedVehicle, 
   vehicleInfo,
    setVehicleInfo, 
    placement,
    setFilledBoxes,
    setUnfilledBoxes,
    setPlacement,
    setStickerStatus
  }) => {
    
  return (

    <Modal
      onClose={() => setIsOpen(false)}
      closeable
      isOpen={isOpen}
      animate
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>
        {/* Vehicle ID : {selectedVehicle.length > 0 ? selectedVehicle[0].label : ''}  */}
      </ModalHeader>
      <ModalBody>
          {/* <div className="container"> */}
              <AddInspectionForm
                  selectedVehicle={selectedVehicle}
                  vehicleInfo={vehicleInfo}
                  setIsOpen={setIsOpen}
                  setFilledBoxes={setFilledBoxes}
                  setVehicleInfo={setVehicleInfo}
                  placement={placement}
                  setPlacement={setPlacement}
                  setUnfilledBoxes={setUnfilledBoxes}
                  setStickerStatus={setStickerStatus}
              />
          {/* </div> */}
      </ModalBody>
    </Modal>
  );
}

