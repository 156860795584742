import React, { useEffect, useState } from 'react'
import {
  Card,
  StyledBody,
  StyledAction,
} from 'baseui/card';
import { FormControl } from 'baseui/form-control';
import { DatePicker } from "baseui/datepicker";
import { SIZE } from "baseui/input";

import MultiFileUploader from '../../../components/MultiFileUploader';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { updateFastVehicle } from '../../../graphql/mutations';
import moment from 'moment';
import { getFastVehicle } from '../../../graphql/queries';
import { toast } from 'react-toastify';
import { Spinner } from 'baseui/spinner';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'baseui/modal';
import { UIButtonForm } from '../../../components/UIComponents';

export default ({
  selectedVehicle,
  vehicleInfo,
  setIsOpen,
  setVehicleInfo,
  placement,
  setFilledBoxes,
  setPlacement,
  setStickerStatus
}) => {
  const [date, setDate] = React.useState('');
  const [files, setFiles] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isDisabel,setIsDisable]=useState(true)
  const uplaodFiles = async () => {
    let mainFiles = [];
    if (files.length > 0) {
      await Promise.all(files.map(async fi => {
        if (fi && (fi.name || fi.key)) {
          try {
            let key = fi.name ? await Storage.put(
              new Date().getTime() + fi.name,
              fi
            ) : await Storage.put(
              fi.key,
              fi);
            mainFiles.push({
              bucket: "calbitwebappuploadbucket1254-dev",
              region: "us-east-2",
              key: key.key
            });

          } catch (error) {

          }
        }
        return null;
      }))
    }
    return mainFiles;
  }

  const submitHandler = async () => {
    setSpinner(true);
    setConfirmModal(false)
    let vehInfo = vehicleInfo;
    const files = await uplaodFiles();
    let inspectionHistory = [];
    if (vehicleInfo.inspectionHistory && vehicleInfo.inspectionHistory.length > 0) {
      inspectionHistory = vehicleInfo.inspectionHistory;
    }
    let sameDate = false;

    let temp = moment(
      new Date(date[0]).toISOString().split("T")[0]
    ).add('day', 1).format("YYYY-MM-DD")
    inspectionHistory.forEach(ins => {
      if (ins.inspectionDate === temp) {
        sameDate = true;
      }
    })

    if (sameDate) {
      toast.error('Inspection For Same Date Already Exits');
      setSpinner(false);
      setConfirmModal(false);
      return;
    } else {
      inspectionHistory.push({
        inspectionDate: moment(
          new Date(date[0]).toISOString().split("T")[0]
        ).add('day', 1).format("YYYY-MM-DD"),
        attachments: files,
        timeStamp: date[0],
        placement
      });
      let input = {
        id: selectedVehicle[0].id,
        inspectionHistory
      }
      vehInfo.inspectionHistory = inspectionHistory;
      setVehicleInfo(vehInfo);
      API.graphql(graphqlOperation(updateFastVehicle, { input }))
        .then(async () => {
          let data = await API.graphql(graphqlOperation(getFastVehicle, { id: input.id }));
          let temp = data.data.getFastVehicle;
          if (temp.inspectionHistory && temp.inspectionHistory.length > 0) {
            temp.inspectionHistory.sort((a, b) => (
              moment(new Date(b.inspectionDate)) - moment(new Date(a.inspectionDate))
            ))
          }
          if (temp.inspectionHistory && temp.inspectionHistory.length > 0) {
            let days = -1 * (moment(new Date(temp.inspectionHistory[0].inspectionDate)).diff(new Date(), 'days') + 1);
            if (days > 365) {
              setStickerStatus(false);
            } else {
              setStickerStatus(true);
            }
          }
          // data = data.data.getFastVehicle;
          let temArr = temp.inspectionHistory;
          let filled = [];
          temArr.length > 0 && temArr.forEach(ta => {
            filled.push(ta.placement);
          })
          setIsOpen(false);
          setConfirmModal(false)
          setSpinner(false);
          setPlacement('')
          setDate('');
          setFilledBoxes(filled);
          setVehicleInfo(temp);
          toast.success('Inspection Added');
          setFiles([]);
        })
        .catch((e) => {
          console.log(e)
          toast.error('Failed');
        });
    }
  };
  useEffect(()=>{
    setTimeout(()=>{
      setIsDisable(false)
    },500)
  },[])
  const ConfirmModal = () => (
    <Modal onClose={() => setConfirmModal(false)} isOpen={confirmModal}>
      <ModalHeader>Confirmation</ModalHeader>
      <ModalBody>
        You are about enter a Federal DOT Annual Date plus backup.
      </ModalBody>
      <ModalFooter>
        <UIButtonForm mini bgcolor="white" color="black" onClick={() => setConfirmModal(false)}>
          Cancel
        </UIButtonForm>
        <UIButtonForm mini onClick={submitHandler}>Okay</UIButtonForm>
      </ModalFooter>
    </Modal>
  )

  return (
    <Card
      overrides={{ Root: { style: { width: '100%' } },Title:{
        style:{fontSize:'16px !important'}
      } }}
      title="Add Inspection Data"
      id="form-error"
    >
      <StyledBody>
        <FormControl label="Date"  >
          <DatePicker
            disabled={spinner}
            value={date}
            onChange={({ date }) =>
              setDate(Array.isArray(date) ? date : [date])
            }
            disabled={isDisabel}
            clearable
            size={SIZE.large}
            autoOpen={false}
            overrides={{
              CalendarHeader: {
                style: ({ }) => ({
                  backgroundColor: "#293377",
                  color:'#ffffff'
                })
              },
              MonthHeader: {
                style: ({ }) => ({
                  backgroundColor: "#293377",
                  color:'#ffffff'

                })
              },
              
              Day: {
                style: ({
                  $selected,
                  $isHovered,
                  $isHighlighted,
                }) => ({
                  color: $selected
                    ? 'black'
                    : 'black',
                  ':after': {
                    backgroundColor: $selected
                      ? $isHovered || $isHighlighted
                        ? 'transparent'
                        : 'transparent'
                      : $isHovered || $isHighlighted
                        ? 'transparent'
                        : 'transparent',
                  },
                })
              },
              Input: {
                props: {
                  overrides: {
                    ClearIcon: {
                      style: ({ $theme }) => ({
                        color: "#293377"
                      })
                    }
                  }
                }
              },
              MonthYearSelectButton: {
                style: ({ }) => ({
                  backgroundColor: "#293377"
                })
              }
            }}
          />
        </FormControl>
        <FormControl label="Upload Images/Files" >
          <MultiFileUploader
            pic={files}
            picFunc={setFiles}
          />
        </FormControl>
      </StyledBody>
      <StyledAction>
        <UIButtonForm
          onClick={() => setConfirmModal(true)}
          disabled={[null, undefined, ''].includes(date) || date[0] === null || spinner}
        >
          {spinner ? <Spinner size={18} /> : "Add"}
        </UIButtonForm>
      </StyledAction>
      <ConfirmModal />
    </Card>
  )
};