import { toast } from "react-toastify";
import {API, graphqlOperation} from 'aws-amplify';

import { ClearStates } from "./Form";
import { updateFastCustomer, updateFastMember, updateFastTerminal, updateFastVehicle } from "../graphql/mutations";
import { uploadPic } from "./Uploads";
import { listFastTerminals } from "../graphql/queries";

export const UpdateVehicle = async (props, input, arr) => {
  let mainPic = null;
  try {
    mainPic = await uploadPic(input.photo);
    if(mainPic) {
      input.photo = mainPic;
    } else {
        if(input.photo) {
            input.photo = null;
        } else
        delete input.photo;
    }
    await API.graphql(graphqlOperation(updateFastVehicle, { input }))
    .then(()=> {
      if(arr.length > 0)    ClearStates(arr);
      props.history.push('/vehicles');
      if(input.isDeactivated) {
          toast.error('Vehicle Deactivated');
      } else {
          toast.success('Vehicle Updated')
      }
    })
    .catch(() => {
      toast.error('Failed');
    })
  } catch (error) {
    toast.error('Failed');
  }
}

export const UpdateTerminal = async (props, input, arr) => {
  let mainPic = null;
  try {
    mainPic = await uploadPic(input.terminalPhoto);
    if(mainPic) {
      input.terminalPhoto = mainPic;
    } else {
        if(input.terminalPhoto) {
            input.terminalPhoto = null;
        } else
        delete input.terminalPhoto;
    }
    let makeDefault = false;
    await API.graphql(graphqlOperation(listFastTerminals, 
          {
            limit : 100, 
            filter: {customerId: {eq: input.customerId} }
          }
        )).then(res => {
          if([null, undefined].includes(res.data.listFastTerminals) || res.data.listFastTerminals.items.length === 0) {
            makeDefault = true;
          }
        })
    if(input.isDefault) {
      makeDefault = true;
    }
    await API.graphql(graphqlOperation(updateFastTerminal, { input }))
    .then(async res=> {
      if(makeDefault) {
        await API.graphql(graphqlOperation(updateFastCustomer, 
          {input: {id: input.customerId, defaultTerminalId: res.data.updateFastTerminal.id}}
        ))
      }
      if(arr.length > 0)    ClearStates(arr);
      props.history.push('/terminals');
      toast.success('Terminal Updated')
    })
    .catch(err => {
      toast.error('Failed');
    })
  } catch (error) {
    toast.error('Failed');
  }
}

export const UpdateMember = async (props, input, arr) => {
  try {
    delete input.username;
    delete input.password;
    await API.graphql(graphqlOperation(updateFastMember, { input }))
      .then(() => {
        if(arr.length > 0)  ClearStates(arr);
        props.history.push('/members');
        toast.success('Member Updated');
      })
      .catch(() => {
        toast.error('Failed')
      })
  } catch (error) {
    
  }
}