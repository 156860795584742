import React from 'react'
import CustomHeader from '../../components/Header'
import '../../styles/index.css'
import AddTerminalForm from './components/AddTerminalForm'

const AddTerminal = (props) => {

    return (
        <>
            <CustomHeader props={props}/>
            <div className="container">
                <AddTerminalForm props={props} />
            </div>
        </>
    )
}

export default AddTerminal
