import React from 'react'
import ConfirmationForm from './components/ConfirmationForm'
import '../../styles/index.css';
import { CopyRightFooter } from '../../components/UIComponents';

const Confirmation = props => {
    const [spinner, setSpinner] = React.useState(false);

    return (
        <>
            <div className="container">
                <ConfirmationForm 
                    props={props} 
                    spin={flag => setSpinner(flag)}
                    spinner={spinner}
                />
            </div>
            <CopyRightFooter />
        </>
    )
}

export default Confirmation
