import React, { useEffect, useState } from 'react';
import {
  StatefulDataTable,
  StringColumn, CustomColumn
} from 'baseui/data-table';
import { Link } from 'react-router-dom';
import { Spinner } from 'baseui/spinner';
import { FiEdit } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
// Files
import '../index.css';
import '../../../styles/index.css'
import { fetchMembers } from '../../../utils/Fetch';
import { memberDelete } from '../utils/handlers';
import Modals from '../../../components/Modals';
import { StatefulTooltip } from "baseui/tooltip";
import { UIButtonNav } from '../../../components/UIComponents';

export default ({ props }) => {
  const [rows, setRows] = useState([]);
  const [showData, setShowData] = useState([])
  const [spinner, setSpinner] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalProps, setModalProps] = useState({
    cancel: { text: '', func: () => { } },
    confirm: { text: '', func: () => { } },
    text: '',
    title: '',
  });

  useEffect(() => {
    fetchMembers(setSpinner, setRows);
  }, [])

  useEffect(() => {
    const grpName = JSON.parse(localStorage.getItem('@loggedUserGroup'));
    let temp = [...rows]
    if (grpName === 'FASTLevel2') {
      temp = temp.filter(item => item.data.level !== "Admin Level 2")
    }
    setShowData(temp)
  }, [rows])

  const handleDeleteClick = id => {
    memberDelete(
      id,
      rows,
      {
        setModal,
        setSpinner,
        setRows,
        setModalProps
      },
      props
    );
  };

  const columns = [
    StringColumn({
      title: 'Username',
      mapDataToValue: (data) => data.id,
    }),
    StringColumn({
      title: 'Name',
      mapDataToValue: (data) => data.memberName,
    }),
    StringColumn({
      title: 'Phone',
      mapDataToValue: (data) => data.phone,
    }),
    StringColumn({
      title: 'Email',
      mapDataToValue: (data) => data.email,
    }),
    StringColumn({
      title: 'Level Assigned',
      mapDataToValue: (data) => <StatefulTooltip
        placement="right"
        content={() => (
          <div padding={"20px"}>
            {data.levelLabel}
          </div>
        )}
        returnFocus
      >
        {data.level}
      </StatefulTooltip>,
    }),
    CustomColumn({
      title: ' ',
      mapDataToValue: (data) => { },
      renderCell: data => {
        return null;
      }
    }),
  ];

  const rowActions = [
    {
      label: 'Edit',
      onClick: ({ row }) => { props.history.push(`/edit-member/${row.data.id}`) },
      renderIcon: () => <FiEdit title="Edit" size="18px" />,
    },
    {
      label: 'Remove',
      onClick: ({ row }) => handleDeleteClick(row.data.id),
      renderIcon: () => <MdDelete size="18px" title="Remove" />,
    }
  ];

  return spinner ? <Spinner size={92} /> : (
    <div className="list-container">
      <Modals
        type={modalProps.type}
        status={modal}
        toggler={st => setModal(st)}
        cancel={modalProps.cancel}
        confirm={modalProps.confirm}
        text={modalProps.text}
        title={modalProps.title}
      />
      <div style={{ paddingBottom: "50px" }}>
        <div style={{ float: 'left' }}>
          <Link to="/">
            <UIButtonNav>
              Back
            </UIButtonNav>
          </Link>
        </div>
        <div style={{ float: 'right' }}>
          <Link to="/add-member">
            <UIButtonNav>
              Add Member
            </UIButtonNav>
          </Link>
        </div>
      </div>
      <StatefulDataTable
        rowActions={rowActions}
        columns={columns}
        rows={showData.length > 0 ? showData : []}
        emptyMessage="No Data Found"
      />
    </div>
  );
};