import * as React from 'react';
import {Card, StyledBody} from 'baseui/card';
import { LabelMedium } from 'baseui/typography';
import { Storage } from 'aws-amplify';
import {ListItem, ListItemLabel} from 'baseui/list';
import {useStyletron} from 'baseui';
import { Spinner } from 'baseui/spinner';

export default ({data}) => {

    const [filesView, setFilesView] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);
    const [css] = useStyletron();

    const getFiles = async files => {
        if(files.length > 0) {
        setSpinner(true);
        let temp = [];
            await Promise.all(files.map(async fi => {
                await Storage.get(fi.key).then(res => {
                    temp.push(res)
                })
                .catch(() => {});
            })).then(() => {
                setFilesView(temp);
                setSpinner(false)
            })
        }
    }

    React.useEffect(() => {
        if(data.attachments) getFiles(data.attachments);
    }, [data.attachments])

return(
  <Card overrides={{Root: {style: {width: '100%'}}}}>
    <StyledBody>
       {/* <LabelLarge>Inspected On : {data.inspectionDate}</LabelLarge> */}
       {spinner ? <Spinner size={45} /> :<>
        {![null, undefined].includes(filesView) ? <>
            {filesView.length > 0 ? <>
                <ul
                    className={css({
                        width: '300px'
                    })}
                >
                    {filesView.map((fi, i) => {
                        return(
                            <ListItem key={i}>
                                <ListItemLabel>
                                    <a href={fi}>
                                        File {i+1}
                                    </a>
                                </ListItemLabel>
                            </ListItem>
                        )
                    })}
                </ul>
            </>
                : 
                <>
                    <LabelMedium>
                        No Attachments
                    </LabelMedium>
                </>
            }
        </> : <>
                    <LabelMedium>
                        No Attachments
                    </LabelMedium>
        </>}

       </>}
    </StyledBody>
  </Card>
    );
}