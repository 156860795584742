import React from 'react';
import CustomHeader from '../../components/Header';
import '../../styles/index.css';
import MembersList from './components/MembersList';

const index = (props) => {
    return (
        <div>
            <CustomHeader props={props}/>
            <div className="container">
                <MembersList props={props} /> 
            </div>
        </div>
    )
}

export default index
