/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:66104606-51b1-481a-90ba-47427fe968af",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_RHTWd9Paq",
    "aws_user_pools_web_client_id": "5s0ot7ocr1v83n15iv83dnpkrm",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://vcy5tuowfraknf6xzsy4eif5xa.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "fastuploads232449-dev",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_content_delivery_bucket": "federal.calbit.live",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "http://federal.calbit.live.s3-website.us-east-2.amazonaws.com"
};


export default awsmobile;
