import React from 'react'
import Routes from './routes'


const Main = ({props}) => {
    return (
        <div>
            <Routes />
        </div>
    )
}

export default Main
