import React from 'react'
import { BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import routes from './routeObjects';

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                {routes.map(route => (
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.isExact}
                        render={props => {
                            const user = JSON.parse(localStorage.getItem('@loggedUser'));
                            const usrGrp = JSON.parse(localStorage.getItem('@loggedUserGroup'));
                            if(route.type === "auth"){
                                return (user !== null && route.viewTo.includes(usrGrp)) ? 
                                    <route.component {...props} /> : 
                                    usrGrp === "FASTLevel4" ? 
                                        <Redirect to={{pathname: '/vehicles'}} />
                                        :
                                        <Redirect to={{pathname: '/sign-in'}} />
                            } else {
                                return user === null ? 
                                    <route.component {...props} /> : 
                                    <Redirect to={{pathname: '/'}} />
                            }
                        }}
                    />
                ))}
            </Switch>
        </BrowserRouter>
    )
}

export default Routes
