import { DeleteMember } from "../../../utils/Deletes";

export const memberDelete = (id, rows, funcs, props) => {
    funcs.setSpinner(true);
    let tempArr = rows;
    funcs.setModalProps({
      cancel: {text: 'No', func: () => funcs.setModal(false)},
      confirm: {text: 'Yes', func: () => {
        funcs.setSpinner(true);
        tempArr = tempArr.filter(t => t.data.id !== id);
        funcs.setRows(tempArr);
        DeleteMember(props, {
          id
        })
        funcs.setModal(false);
        funcs.setSpinner(false);
      }}, 
      text: 'Are You Sure ?',
      type: 'Delete',
      title: 'Delete Member',
    })
    funcs.setModal(true);
    funcs.setSpinner(false);
}