import React, { useState, useEffect } from 'react'
import StickerSVG from './Stick.svg'
import '../index.css';
import AddInspectionModal from './AddInspectionModal';
import { CompanyInfo } from './CustomerInfo';
import './index.css';
import VehicleListSelect from './VehicleListSelect';
import ViewInspectionModal from './ViewInspectionModal';
import { fetchCustomer } from '../../../utils/Fetch';
import TerminalListSelect from './TerminalListSelect';
import { Spinner } from 'baseui/spinner';
import { BsArrowsMove } from 'react-icons/bs'
import { API, graphqlOperation } from 'aws-amplify';
import { inspectionDump, updateFastVehicle } from '../../../graphql/mutations';
import { getFastVehicle } from '../../../graphql/queries';
import moment from 'moment'
import Readytogo from '../Readytogo.PNG';
import RedTag from '../Redtag.PNG';
import TerminalModal from './TerminalModal'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'baseui/modal';
import { GrAdd } from 'react-icons/gr'
import { UIButtonForm, UIButtonNav } from '../../../components/UIComponents';
import { Tag } from 'baseui/tag';
import { toast } from 'react-toastify';
import VehicleMap from '../components/VehicleMap'


const App = ({ props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [vehiclesList, setVehiclesList] = useState([]);
  const [terminalsList, setTerminalsList] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [defaultTerminal, setDefaultTerminal] = useState(null);
  const [moveable, setMoveable] = useState(false);
  const [placement, setPlacement] = useState('');
  const [deleteThis, setDeleteThis] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [changing, setChanging] = useState(false);
  const [filledBoxes, setFilledBoxes] = useState([]);
  const [data, setData] = React.useState([]);
  const [showData, setShowData] = React.useState([]);
  const [showSticker, setShowSticker] = React.useState(false);
  const [stickerStatus, setStickerStatus] = useState(true);
  const [activeType, setActiveType] = useState('ALL');
  const [vehicleTypes, setVehicleTypes] = useState(['ALL']);
  const [inspectionHistory, setInspectionHistory] = useState([])
  const [placementOne, setPlacementOne] = useState('')
  const [placementTwo, setPlacementTwo] = useState('')
  const [showTerminalModal, setShowTerminalModal] = useState(false)
  const [selectedVehiclesList, setSelectedVehicleList] = useState([])
  const [activeTerminal, setActiveTerminal] = useState({})
  const [initialCenter, SetInitialCenter] = useState({ lat: 36.778259, lng: -119.417931 })
  const [deleteSpinner, setDeleteSpinner] = useState(false)

  useEffect(() => {
    setSpinner(true);
    fetchCustomer()
      .then(res => {
        const { companyAddress, companyName, terminals, vehicles, defaultTerminal } = res;

        if (res) {
          if (vehicles && vehicles.length) {
            vehicles.forEach((veh, index) => {
              let terminal = terminals.find(terminal => terminal.id === veh.terminalId)

              if (terminal) {
                vehicles[index] = {
                  ...veh,
                  lat: parseFloat(terminal.terminalLat),
                  lng: parseFloat(terminal.terminalLong)
                }
              }
              if (!veh.isDeactivated) {
                let arr = vehicleTypes;
                if (!arr.includes(veh.type.toUpperCase())) {
                  arr.push(veh.type.toUpperCase());
                }
                setVehicleTypes(arr);
              }
            })
            let temp = vehicles.filter(veh => veh.isDeactivated === false)
            setData(temp);
            if (activeType !== 'ALL') {
              setShowData(temp.filter(t => t.type.toUpperCase() === activeType.toUpperCase()));
            } else {
              setShowData(temp);
            }
          }
          if (defaultTerminal === null) {
            if (terminals.length > 0) {
              SetInitialCenter({
                lat: parseFloat(terminals[0].terminalLat),
                lng: parseFloat(terminals[0].terminalLong)
              })
            }
          } else {
            terminals.forEach(item => {
              if (item.id === defaultTerminal) {
                SetInitialCenter({
                  lat: parseFloat(item.terminalLat),
                  lng: parseFloat(item.terminalLong)
                })
              }
            })
          }

          setCompanyName(companyName ? companyName : '');
          setCompanyAddress(companyAddress ? companyAddress : '');
          setTerminalsList(terminals && terminals.length ? terminals : []);
          setVehiclesList(vehicles && vehicles.length ? vehicles.filter(veh => veh.isDeactivated === false) : []);
          setDefaultTerminal(defaultTerminal ? defaultTerminal : null);
        }
      })
      .then(() => {
        setSpinner(false);
      });
  }, [])

  //set Vehicle List 

  useEffect(() => {
    let tempPlacement = [];
    let historyList = []
    if (vehicleInfo && vehicleInfo.inspectionHistory) {
      let temp = vehicleInfo.inspectionHistory;

      temp.forEach(item => {
        if (!tempPlacement.includes(item.placement)) {
          tempPlacement.push(item.placement)
          historyList.push(item)
        }
      })
    }
    let numbers = ['1', '2', '3', '4', '5', '6', '7', '8'];
    numbers.forEach(placement => {
      if (!tempPlacement.includes(placement)) {
        historyList.push({
          placement
        })
      }
    })
    historyList.sort((a, b) => a.placement - b.placement)
    setInspectionHistory(historyList)
  }, [vehicleInfo])

  const reloadVehicle = () => {
    let temp = vehicleInfo.inspectionHistory;
    let tempPlacement = [];
    let historyList = []
    temp.forEach(item => {
      if (!tempPlacement.includes(item.placement)) {
        tempPlacement.push(item.placement)
        historyList.push(item)
      }
    })
    let numbers = ['1', '2', '3', '4', '5', '6', '7', '8'];
    numbers.forEach(placement => {
      if (!tempPlacement.includes(placement)) {
        historyList.push({
          placement
        })
      }
    })
    historyList.sort((a, b) => a.placement - b.placement)
    setInspectionHistory(historyList)
  }

  const deleteHandler = toDelete => {
    setDeleteThis(toDelete);
    setDeleteConfirm(true);
  }

  const DeleteInspectionHist = async () => {
    setDeleteSpinner(true);
    let vehInfo = vehicleInfo;
    let tempInspHist = vehicleInfo.inspectionHistory.filter(temp => temp.placement === deleteThis.placement && temp.inspectionDate === deleteThis.inspectionDate);
    let remInspHist = vehicleInfo.inspectionHistory.filter(temp => temp.placement !== deleteThis.placement && temp.inspectionDate !== deleteThis.inspectionDate);
    let input = {
      vin: vehicleInfo.id,
      deletedInspection: {
        inspectionDate: tempInspHist[0].inspectionDate,
        attachments: tempInspHist[0].attachments,
        timeStamp: tempInspHist[0].timeStamp ? tempInspHist[0].timeStamp : null,
        placement: tempInspHist[0].placement,
      },
      newInspectionHistory: remInspHist
    }
    vehInfo.inspectionHistory = remInspHist;

    if (vehicleInfo.inspectionHistory.length === 0) {
      setStickerStatus(true);
    }

    setChanging(!changing);
    setVehicleInfo(vehInfo);
    setDeleteThis(null);
    let temp3 = filledBoxes.filter(fb => fb !== tempInspHist[0].placement);
    setFilledBoxes(temp3);
    await API.graphql(graphqlOperation(inspectionDump, { input }))
      .then(() => {
      })
      .catch((err) => {

      });
    let input1 = {
      id: vehicleInfo.id,
      inspectionHistory: remInspHist
    }
    await API.graphql(graphqlOperation(updateFastVehicle, { input: input1 }))
      .then(async () => {
        let data = await API.graphql(graphqlOperation(getFastVehicle, { id: input1.id }));
        let temp = data.data.getFastVehicle;
        if (temp.inspectionHistory && temp.inspectionHistory.length > 0) {
          temp.inspectionHistory.sort((a, b) => (
            moment(new Date(b.inspectionDate)) - moment(new Date(a.inspectionDate))
          ))
        }
        if (temp.inspectionHistory && temp.inspectionHistory.length > 0) {
          let days = -1 * (moment(new Date(temp.inspectionHistory[0].inspectionDate)).diff(new Date(), 'days') + 1);
          if (days > 365) {
            setStickerStatus(false);
          } else {
            setStickerStatus(true);
          }
        }
        setDeleteSpinner(false)
        reloadVehicle()
        setViewOpen(false);
        setDeleteConfirm(false)
        setPlacement('');
      })
      .catch(() => {
        setSpinner(false)
      });
  }

  const MapClickHandler = async (veh) => {
    setShowSticker(true);
    setIsLoading(true);
    setVehicleInfo(null);

    await API.graphql(graphqlOperation(getFastVehicle,
      { id: veh.id }
    )).then(data => {
      let temp = data.data.getFastVehicle;
      if (temp.inspectionHistory && temp.inspectionHistory.length > 0) {
        temp.inspectionHistory.sort((a, b) => (
          moment(new Date(b.inspectionDate)) - moment(new Date(a.inspectionDate))
        ))
      }
      if (temp.inspectionHistory && temp.inspectionHistory.length > 0) {
        let days = -1 * (moment(new Date(temp.inspectionHistory[0].inspectionDate)).diff(new Date(), 'days') + 1);
        if (days > 365) {
          setStickerStatus(false);
        } else {
          setStickerStatus(true);
        }
      }
      let temArr = temp.inspectionHistory;
      let filled = [];
      temArr && temArr.length > 0 && temArr.map(ta => {
        filled.push(ta.placement);
      })

      setFilledBoxes(filled);
      setIsLoading(false);
      setVehicleInfo(temp);
      setSelected([
        temp
      ]);
      let tempArr = terminalsList.filter(tl =>
        tl.id === veh.terminalId
      );
      setSelectedTerminal([{
        id: tempArr[0].id,
        label: tempArr[0].terminalName
      }])
    });
  };

  const tagClickHandler = (vt) => {
    let type = vt.toUpperCase();
    setActiveType(type);
    let temp = data;
    if (type !== 'ALL') {
      setShowData(temp.filter(t => t.type.toUpperCase() === type));
    } else {
      setShowData(temp);
    }
  }

  const setElemntPlacement = (place) => {
    if (placementOne === place) {
      document.getElementById(place).style.border = 'none'
      setPlacementOne('')
      return;
    }
    if (placementOne === '') {
      setPlacementOne(place)
      return;
    }
    if (placementTwo === '') {
      setPlacementTwo(place)
      togglePlacement(placementOne, place)
    }
  }

  const togglePlacement = async (place1, place2) => {
    let index1 = inspectionHistory.findIndex(item => item.placement === place1)
    let index2 = inspectionHistory.findIndex(item => item.placement === place2)
    let temp = inspectionHistory[index1]
    let tempArray = [...inspectionHistory]
    tempArray[index1] = {
      ...tempArray[index2],
      placement: place1
    }
    tempArray[index2] = {
      ...temp,
      placement: place2
    }
    let input = {
      id: vehicleInfo.id,
      inspectionHistory: tempArray.filter(item => item.inspectionDate)
    }

    try {
      if (input.inspectionHistory.length > 0) {
        await API.graphql(graphqlOperation(updateFastVehicle, { input }))
        await API.graphql(graphqlOperation(getFastVehicle, { id: input.id }));
      }
      setInspectionHistory(tempArray)
      setPlacementOne('')
      setPlacementTwo('')
    }
    catch (e) {
      toast.error('Inspect history not update')
      console.error(e)
    }
  }

  const clickTerminalIcon = id => {
    let temp = showData.filter(item => item.terminalId === id)
    let tempTerminal = terminalsList.find(item => item.id === id)
    setSelectedVehicleList(temp)
    setShowTerminalModal(!showTerminalModal)
    setActiveTerminal(tempTerminal)
  }
  return spinner ?
    <div className="spinner">
      <Spinner size={72} />
    </div> : (
      <>
        <AddInspectionModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setPlacement={setPlacement}
          selectedVehicle={selected}
          vehicleInfo={vehicleInfo}
          setVehicleInfo={setVehicleInfo}
          placement={placement}
          setFilledBoxes={setFilledBoxes}
          setStickerStatus={setStickerStatus}
        />
        <ViewInspectionModal
          viewOpen={viewOpen}
          setViewOpen={setViewOpen}
          selectedVehicle={selected}
          selectedDate={selectedDate}
          placement={placement}
          deleteHandler={arg => deleteHandler(arg)}
          deleteSpinner={deleteSpinner}
        />
        <Modal
          onClose={() => setDeleteConfirm(false)} isOpen={deleteConfirm}>
          <ModalHeader>Delete Inspection History</ModalHeader>
          <ModalBody>
            Are You Sure ?
          </ModalBody>
          <ModalFooter>
            <UIButtonForm mini bgcolor="white" color="black" onClick={() => setDeleteConfirm(false)}>
              Cancel
            </UIButtonForm>
            <UIButtonForm mini onClick={() => DeleteInspectionHist()}>{deleteSpinner ? <Spinner size={16} /> : 'Yes'}</UIButtonForm>
          </ModalFooter>
        </Modal>
        <TerminalModal open={showTerminalModal} onClose={setShowTerminalModal} data={selectedVehiclesList} action={MapClickHandler} terminal={activeTerminal} />
        {/* Inspectio History Modal */}
        <Modal
          closeable={false}
          onClose={() => setShowSticker(false)} isOpen={showSticker}>
          <ModalHeader className="sticker-modal-header">
            <div className="navigation-btn-group">
              <div>
                {
                  <UIButtonNav onClick={() => setShowSticker(false)}>
                    Back
                  </UIButtonNav>
                }
              </div>
              <div>
                {vehicleInfo && vehicleInfo.inspectionHistory && vehicleInfo.inspectionHistory.length > 0 && selected.length > 0 && <div>
                  <img src={stickerStatus ? Readytogo : RedTag} height={50} width={50} />
                </div>}
              </div>
              <div>
                {vehicleInfo && <>
                  <UIButtonNav onClick={() => props.history.push(`/edit-vehicle/${vehicleInfo.id}`)}>
                    Details
                  </UIButtonNav>
                </>}
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="sticker-modal-body">
            <div className="image-container">
              <img src={StickerSVG} className="bg-image" />
              <div className="dates-list">
                {
                  inspectionHistory.slice(0, 8).map(inspection => {
                    return inspection.inspectionDate ?
                      <div className="dates-list-item"
                        id={inspection.placement}
                        style={{ border: placementOne === inspection.placement ? '2px solid blue' : 'none' }}
                      >
                        <del
                          onClick={() => {
                            setViewOpen(true);
                            setSelectedDate(inspection);
                          }}
                        >
                          {moment(inspection.inspectionDate).format("YYYY MMM Do")}
                        </del>
                        <span
                          id="drag-click"
                          className="drag-click"
                          onClick={() => setElemntPlacement(inspection.placement)}
                        >
                          <BsArrowsMove size={12} />
                        </span>
                      </div>
                      : <div
                        id={inspection.placement}
                        className="dates-list-item"
                        style={{ border: placementOne === inspection.placement ? '2px solid blue' : 'none' }}
                      >
                        <GrAdd size={16}
                          onClick={() => {
                            if (selected.length > 0) {
                              setPlacement(inspection.placement);
                              setIsOpen(true)
                            }
                          }}
                        />
                        <span style={{ cursor: 'move', marginLeft: 5 }}
                          id="drag-click"
                          onClick={() => setElemntPlacement(inspection.placement)}
                        >
                          <BsArrowsMove size={12} />
                        </span>
                      </div>
                  })
                }
              </div>
              <div className="id-dropdown">
                {
                  selectedTerminal.length > 0 &&
                  <VehicleListSelect
                    selected={selected}
                    setFilledBoxes={setFilledBoxes}
                    moveable={moveable}
                    vehiclesList={vehiclesList}
                    selectedTerminal={selectedTerminal}
                    setSelected={setSelected}
                    setVehicleInfo={setVehicleInfo}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    setStickerStatus={setStickerStatus}
                  />
                }
              </div>
              <div className="terminal-list">
                {
                  <TerminalListSelect
                    isLoading={isLoading}
                    moveable={moveable}
                    defaultTerminal={defaultTerminal}
                    terminalsList={terminalsList}
                    selectedTerminal={selectedTerminal}
                    setSelectedTerminal={setSelectedTerminal}
                    setSelected={setSelected}
                    setVehicleInfo={setVehicleInfo}
                  />
                }
              </div>
              <div className="address-info">
                <CompanyInfo set="address" companyAddress={companyAddress} />
              </div>
            </div>

          </ModalBody>
        </Modal>
        < >
          {
            <div className="map_canvas">
              <div className="center-style">
                {!showSticker &&
                  <React.Fragment>
                    {showData.length > 0 ? vehicleTypes.map(vt => <React.Fragment>
                      <Tag
                        closeable={false}
                        variant={activeType === vt ? "solid" : "light"}
                        onClick={() => tagClickHandler(vt)}
                      >
                        {vt}
                      </Tag>
                    </React.Fragment>) : <>
                      No vehicle find Please <span className="add-vehicel-link" onClick={() => props.history.push('/add-vehicle')}> Add Vehicle</span>
                    </>}
                  </React.Fragment>
                }
              </div>
              <VehicleMap MapClickHandler={MapClickHandler} initialCenter={initialCenter} data={showData} clickTerminalIcon={clickTerminalIcon} />
            </div>
          }

        </>
      </>
    );
}

export default App;