/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const inspectionDump = /* GraphQL */ `
  mutation InspectionDump($input: InspectionDumpInput!) {
    InspectionDump(input: $input)
  }
`;
export const createFastCustomer = /* GraphQL */ `
  mutation CreateFastCustomer(
    $input: CreateFastCustomerInput!
    $condition: ModelFastCustomerConditionInput
  ) {
    createFastCustomer(input: $input, condition: $condition) {
      id
      carrierName
      DOTNumber
      carrierAddress
      defaultTerminalId
      members {
        items {
          id
          customerId
          memberName
          phone
          email
          terminalAssignedToID
          terminalAssignedToName
          groupsAssigned
          createdAt
          updatedAt
        }
        nextToken
      }
      terminals {
        items {
          id
          customerId
          terminalName
          terminalAddress
          terminalLat
          terminalLong
          description
          isDefault
          isDeactivated
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicles {
        items {
          id
          vehicleId
          customerId
          terminalId
          isOutOfService
          isDeactivated
          type
          year
          make
          model
          nextDueDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFastCustomer = /* GraphQL */ `
  mutation UpdateFastCustomer(
    $input: UpdateFastCustomerInput!
    $condition: ModelFastCustomerConditionInput
  ) {
    updateFastCustomer(input: $input, condition: $condition) {
      id
      carrierName
      DOTNumber
      carrierAddress
      defaultTerminalId
      members {
        items {
          id
          customerId
          memberName
          phone
          email
          terminalAssignedToID
          terminalAssignedToName
          groupsAssigned
          createdAt
          updatedAt
        }
        nextToken
      }
      terminals {
        items {
          id
          customerId
          terminalName
          terminalAddress
          terminalLat
          terminalLong
          description
          isDefault
          isDeactivated
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicles {
        items {
          id
          vehicleId
          customerId
          terminalId
          isOutOfService
          isDeactivated
          type
          year
          make
          model
          nextDueDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFastCustomer = /* GraphQL */ `
  mutation DeleteFastCustomer(
    $input: DeleteFastCustomerInput!
    $condition: ModelFastCustomerConditionInput
  ) {
    deleteFastCustomer(input: $input, condition: $condition) {
      id
      carrierName
      DOTNumber
      carrierAddress
      defaultTerminalId
      members {
        items {
          id
          customerId
          memberName
          phone
          email
          terminalAssignedToID
          terminalAssignedToName
          groupsAssigned
          createdAt
          updatedAt
        }
        nextToken
      }
      terminals {
        items {
          id
          customerId
          terminalName
          terminalAddress
          terminalLat
          terminalLong
          description
          isDefault
          isDeactivated
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicles {
        items {
          id
          vehicleId
          customerId
          terminalId
          isOutOfService
          isDeactivated
          type
          year
          make
          model
          nextDueDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFastMember = /* GraphQL */ `
  mutation CreateFastMember(
    $input: CreateFastMemberInput!
    $condition: ModelFastMemberConditionInput
  ) {
    createFastMember(input: $input, condition: $condition) {
      id
      customerId
      customer {
        id
        carrierName
        DOTNumber
        carrierAddress
        defaultTerminalId
        members {
          nextToken
        }
        terminals {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
      }
      memberName
      phone
      email
      terminalAssignedToID
      terminalAssignedToName
      groupsAssigned
      createdAt
      updatedAt
    }
  }
`;
export const updateFastMember = /* GraphQL */ `
  mutation UpdateFastMember(
    $input: UpdateFastMemberInput!
    $condition: ModelFastMemberConditionInput
  ) {
    updateFastMember(input: $input, condition: $condition) {
      id
      customerId
      customer {
        id
        carrierName
        DOTNumber
        carrierAddress
        defaultTerminalId
        members {
          nextToken
        }
        terminals {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
      }
      memberName
      phone
      email
      terminalAssignedToID
      terminalAssignedToName
      groupsAssigned
      createdAt
      updatedAt
    }
  }
`;
export const deleteFastMember = /* GraphQL */ `
  mutation DeleteFastMember(
    $input: DeleteFastMemberInput!
    $condition: ModelFastMemberConditionInput
  ) {
    deleteFastMember(input: $input, condition: $condition) {
      id
      customerId
      customer {
        id
        carrierName
        DOTNumber
        carrierAddress
        defaultTerminalId
        members {
          nextToken
        }
        terminals {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
      }
      memberName
      phone
      email
      terminalAssignedToID
      terminalAssignedToName
      groupsAssigned
      createdAt
      updatedAt
    }
  }
`;
export const createFastTerminal = /* GraphQL */ `
  mutation CreateFastTerminal(
    $input: CreateFastTerminalInput!
    $condition: ModelFastTerminalConditionInput
  ) {
    createFastTerminal(input: $input, condition: $condition) {
      id
      customerId
      customer {
        id
        carrierName
        DOTNumber
        carrierAddress
        defaultTerminalId
        members {
          nextToken
        }
        terminals {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
      }
      terminalName
      terminalAddress
      terminalLat
      terminalLong
      description
      terminalPhoto {
        bucket
        region
        key
      }
      isDefault
      vehicles {
        items {
          id
          vehicleId
          customerId
          terminalId
          isOutOfService
          isDeactivated
          type
          year
          make
          model
          nextDueDate
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeactivated
      createdAt
      updatedAt
    }
  }
`;
export const updateFastTerminal = /* GraphQL */ `
  mutation UpdateFastTerminal(
    $input: UpdateFastTerminalInput!
    $condition: ModelFastTerminalConditionInput
  ) {
    updateFastTerminal(input: $input, condition: $condition) {
      id
      customerId
      customer {
        id
        carrierName
        DOTNumber
        carrierAddress
        defaultTerminalId
        members {
          nextToken
        }
        terminals {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
      }
      terminalName
      terminalAddress
      terminalLat
      terminalLong
      description
      terminalPhoto {
        bucket
        region
        key
      }
      isDefault
      vehicles {
        items {
          id
          vehicleId
          customerId
          terminalId
          isOutOfService
          isDeactivated
          type
          year
          make
          model
          nextDueDate
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeactivated
      createdAt
      updatedAt
    }
  }
`;
export const deleteFastTerminal = /* GraphQL */ `
  mutation DeleteFastTerminal(
    $input: DeleteFastTerminalInput!
    $condition: ModelFastTerminalConditionInput
  ) {
    deleteFastTerminal(input: $input, condition: $condition) {
      id
      customerId
      customer {
        id
        carrierName
        DOTNumber
        carrierAddress
        defaultTerminalId
        members {
          nextToken
        }
        terminals {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
      }
      terminalName
      terminalAddress
      terminalLat
      terminalLong
      description
      terminalPhoto {
        bucket
        region
        key
      }
      isDefault
      vehicles {
        items {
          id
          vehicleId
          customerId
          terminalId
          isOutOfService
          isDeactivated
          type
          year
          make
          model
          nextDueDate
          createdAt
          updatedAt
        }
        nextToken
      }
      isDeactivated
      createdAt
      updatedAt
    }
  }
`;
export const createFastVehicle = /* GraphQL */ `
  mutation CreateFastVehicle(
    $input: CreateFastVehicleInput!
    $condition: ModelFastVehicleConditionInput
  ) {
    createFastVehicle(input: $input, condition: $condition) {
      id
      vehicleId
      customerId
      customer {
        id
        carrierName
        DOTNumber
        carrierAddress
        defaultTerminalId
        members {
          nextToken
        }
        terminals {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
      }
      terminalId
      isOutOfService
      isDeactivated
      type
      year
      make
      model
      photo {
        bucket
        region
        key
      }
      nextDueDate
      inspectionHistory {
        inspectionDate
        attachments {
          bucket
          region
          key
        }
        timeStamp
        placement
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFastVehicle = /* GraphQL */ `
  mutation UpdateFastVehicle(
    $input: UpdateFastVehicleInput!
    $condition: ModelFastVehicleConditionInput
  ) {
    updateFastVehicle(input: $input, condition: $condition) {
      id
      vehicleId
      customerId
      customer {
        id
        carrierName
        DOTNumber
        carrierAddress
        defaultTerminalId
        members {
          nextToken
        }
        terminals {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
      }
      terminalId
      isOutOfService
      isDeactivated
      type
      year
      make
      model
      photo {
        bucket
        region
        key
      }
      nextDueDate
      inspectionHistory {
        inspectionDate
        attachments {
          bucket
          region
          key
        }
        timeStamp
        placement
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFastVehicle = /* GraphQL */ `
  mutation DeleteFastVehicle(
    $input: DeleteFastVehicleInput!
    $condition: ModelFastVehicleConditionInput
  ) {
    deleteFastVehicle(input: $input, condition: $condition) {
      id
      vehicleId
      customerId
      customer {
        id
        carrierName
        DOTNumber
        carrierAddress
        defaultTerminalId
        members {
          nextToken
        }
        terminals {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
      }
      terminalId
      isOutOfService
      isDeactivated
      type
      year
      make
      model
      photo {
        bucket
        region
        key
      }
      nextDueDate
      inspectionHistory {
        inspectionDate
        attachments {
          bucket
          region
          key
        }
        timeStamp
        placement
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFastInspectionHistoryDump = /* GraphQL */ `
  mutation CreateFastInspectionHistoryDump(
    $input: CreateFastInspectionHistoryDumpInput!
    $condition: ModelFastInspectionHistoryDumpConditionInput
  ) {
    createFastInspectionHistoryDump(input: $input, condition: $condition) {
      id
      vin
      inspectionDate
      attachments {
        bucket
        region
        key
      }
      timeStamp
      createdAt
      updatedAt
    }
  }
`;
export const updateFastInspectionHistoryDump = /* GraphQL */ `
  mutation UpdateFastInspectionHistoryDump(
    $input: UpdateFastInspectionHistoryDumpInput!
    $condition: ModelFastInspectionHistoryDumpConditionInput
  ) {
    updateFastInspectionHistoryDump(input: $input, condition: $condition) {
      id
      vin
      inspectionDate
      attachments {
        bucket
        region
        key
      }
      timeStamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteFastInspectionHistoryDump = /* GraphQL */ `
  mutation DeleteFastInspectionHistoryDump(
    $input: DeleteFastInspectionHistoryDumpInput!
    $condition: ModelFastInspectionHistoryDumpConditionInput
  ) {
    deleteFastInspectionHistoryDump(input: $input, condition: $condition) {
      id
      vin
      inspectionDate
      attachments {
        bucket
        region
        key
      }
      timeStamp
      createdAt
      updatedAt
    }
  }
`;
