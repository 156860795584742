import * as React from "react";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavbarText,
  NavItem
} from 'reactstrap';

import { Link, NavLink } from "react-router-dom";
import { AnnualSticker } from '../assests/SVGs'
import './index.css';
import { getUserCognito, setSignOut } from "../utils/Auth";
const userName = JSON.parse(localStorage.getItem('@loggedUser'));
const grpName = JSON.parse(localStorage.getItem('@loggedUserGroup'));
const companyName = JSON.parse(localStorage.getItem('@companyName'))
const CustomHeader = ({ props }) => {
  const [isLogged, setIsLogged] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);
  React.useEffect(() => {
    getUserCognito(setIsLogged, null, props)
      .then(() => {
        setLoading(false);
      });
  }, [props])

  const NavButton = ({ children, ...props }) =>
    <button
      style={{
        backgroundColor: 'white',
        borderRadius: '5px',
        color: 'black',
        padding: '2px 32px',
        textAlign: 'center',
        display: 'inline-block',
        fontSize: '16px',
        margin: '4px 2px',
        cursor: 'pointer'
      }}
      {...props}
    >
      {children}
    </button>
  // let roleName = '';
  // switch (grpName) {
  //   case 'FASTCustomer':
  //     roleName = 'Owner Full Control';
  //     break;
  //   case 'FASTLevel2':
  //     roleName = 'Admin Control';
  //     break;
  //   case 'FASTLevel3':
  //     roleName = 'Partial Admin Control';
  //     break;
  //   case 'FASTLevel4':
  //     roleName = 'Update Control';
  //     break;
  //   default: roleName = '';
  // }
  return (
    <Navbar className="topnav" dark expand="md">
      <NavbarBrand className="brandLogo" href="/">
        <NavLink className="link-nav"
          to={!isLogged ? "/sign-in" : "/"}
        >
          <AnnualSticker />
        </NavLink>
      </NavbarBrand>
      <NavbarToggler className="mr-2" onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        {isLogged ? <Nav className="mr-auto" navbar>
          {grpName !== 'FASTLevel3' && grpName !== 'FASTLevel4' &&
            <>
              <NavItem>
                <NavLink to="/members" >Members</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/terminals">Terminals</NavLink>
              </NavItem>
            </>
          }
          {grpName !== 'FASTLevel4' &&<NavItem>
            <NavLink to="/vehicles">Vehicles</NavLink>
          </NavItem>}
        </Nav> : <Nav className="mr-auto" navbar></Nav>}
        <NavbarText>
          {!loading && <>
            {!isLogged ? <>
              <Link to='/sign-in'>
                <NavButton>Login</NavButton>
              </Link>
              <Link to='/sign-up'>
                <NavButton>Register</NavButton>
              </Link>
            </> :
              <div className="right-nav">
                <div style={{ color: 'white',marginRight:15}} >
                  <div style={{}}>
                    {companyName}
                  </div>
                  <div >
                    Welcome {userName}
                  </div>
                  {/* <div >
                    {roleName}
                  </div> */}
                </div>
                <button className="logout-btn" onClick={() => setSignOut(props)}>
                  Log Out
                </button>
              </div>
            }
          </>}


        </NavbarText>
      </Collapse>
    </Navbar>


  );
}

export default CustomHeader;