const { UpdateVehicle } = require("../../../utils/Updates");

export const vehicleDelete = (vin, rows, funcs, props) => {
    funcs.setSpinner(true);
    let tempArr = rows;
    funcs.setModalProps({
      cancel: {text: 'No', func: () => funcs.setModal(false)},
      confirm: {text: 'Yes', func: () => {
        funcs.setSpinner(true);
        tempArr = tempArr.filter(t => t.data.VIN !== vin);
        funcs.setRows(tempArr);
        UpdateVehicle(props, {
          id: vin, 
          isDeactivated: true
        }, [])
        funcs.setModal(false);
        funcs.setSpinner(false);
      }}, 
      text: 'Are You Sure ?',
      type: 'Delete',
      title: 'Deactivate Vehicle',
    })
    funcs.setModal(true);
    funcs.setSpinner(false);
}

export const vehicleChangeStatus = async(vin, rows, funcs, props) => {
    let tempArr = rows;
    await tempArr.map(t => {
      if(t.data.VIN === vin) {
        funcs.setSpinner(true);
        funcs.setModalProps({
          cancel: {text: 'No', func: () => funcs.setModal(false)},
          confirm: {text: 'Yes', func: () => {
            funcs.setSpinner(true);
            t.data.isOutOfService = !t.data.isOutOfService;
            UpdateVehicle(props, {
              id: t.data.VIN, 
              isOutOfService: t.data.isOutOfService ? true : false
            }, [])
            funcs.setModal(false);
            funcs.setSpinner(false);
          }}, 
          text: 'Are You Sure ?',
          type: 'Confirm',
          title: t.data.isOutOfService ? 'Place Back In Service' : 'Place Out Of Service',
        })
        funcs.setModal(true);
      }
      return null;
    });
    await funcs.setRows(tempArr);
    funcs.setSpinner(false);
}