import React from "react";
import { Link } from "react-router-dom";
import {UIButtonNav} from '../../../components/UIComponents';
import './index.css'
const usrGrp = JSON.parse(localStorage.getItem('@loggedUserGroup'));

export default () => {

  return (
        <div className="btn-container">
            {["FASTCustomer", "FASTLevel2"].includes(usrGrp) &&
            <div className="btn">
                <Link to="/members">
                    <UIButtonNav>
                        Members
                    </UIButtonNav>
                </Link>
            </div>}
            {["FASTCustomer", "FASTLevel2"].includes(usrGrp) && <div className="btn">
                <Link to="/terminals">
                    <UIButtonNav>
                        Terminals
                    </UIButtonNav>
                </Link>
            </div>}
            {["FASTCustomer", "FASTLevel2","FASTLevel3", "FASTLevel4"].includes(usrGrp) && 
            <div className="btn">
                <Link to="/vehicles">
                    <UIButtonNav>
                        Vehicles
                    </UIButtonNav>
                </Link>
            </div>}
        </div>
  );
}