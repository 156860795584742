import React from 'react'
import Geocode from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
// import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { InputValidation } from '../utils/Form';
import './index.css';

Geocode.setApiKey("AIzaSyCjDtbSM_fk8MM9pHkEXZqMf4ijQhRWJoM");
Geocode.enableDebug();


class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            mapPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng
            },
        }
    }

    componentDidMount() {
        Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
            response => {
                const address = response.results[0].formatted_address;
                this.setState({
                    address: (address) ? address : ''
                }, () => {
                    this.props.setAddress((address) ? address : '');
                })
            },
            error => {
                this.setState({
                    address: this.props.state.address ? this.props.state.address : ''
                }, () => {
                    this.props.setAddress(this.props.state.address ? this.props.state.address : '');
                })
                // console.error(error);
            }
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.state.address !== nextState.address
        ) {
            return true
        } else if (this.props.center.lat === nextProps.center.lat) {
            return false
        }
    }

    onPlaceSelected = (place) => {

        const address = place.formatted_address,
            latValue = place.geometry.location.lat(),
            lngValue = place.geometry.location.lng();

        this.setState({
            address: (address) ? address : '',
            mapPosition: {
                lat: latValue,
                lng: lngValue
            },
        }, () => {
            this.props.setAddress((address) ? address : '');
        })
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        let map;
        map = <div>
            <Autocomplete
                className="map-autocomplete"
                placeholder="Find Your Street address"
                onChange={e => this.setState({ address: e.target.value })}
                onPlaceSelected={this.onPlaceSelected}
                value={this.state.address}
                onBlur={() => InputValidation('address', [this.props.state.address], this.props.errs, this.props.errFunc)}
                types={["address"]}
                componentRestrictions={{ country: "us" }}
                fields={[
                    "geometry",
                    "formatted_address",
                    "address_components"
                ]}
            />
            <div>

            </div>
        </div>
        return (map)
    }
}
export default Map